// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import "../../styles/modal.css";

class ModalDeleteForm extends Component { 
    

  render() {
    return (
      <div
        className="modal-delete-form"
        style={{ display: this.props.display }}
      >
        <div className="modal-content-delete-form">
          <span className="close-delete-form" onClick={() => this.props.closeModal()}>
            &times;
          </span>
          <p>
            ¿Esta seguro de eliminar el formulario de{" "}
            <b>{this.props.form.name}</b>?
          </p>
          <button
            className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
            onClick={() => this.props.goToDelete()}
          >
            Eliminar
          </button>
          <button
            className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
            onClick={() => this.props.closeModal()}
          >
            {" "}
            Cancelar
          </button>
        </div>
      </div>
    );
  }
}

export default ModalDeleteForm;
