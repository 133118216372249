import React from "react";

class SpinnerLayout extends React.Component {
  render () {
    return(
        <div>
          {this.props.msg}            
        </div>
    );
  }
}
export default SpinnerLayout
