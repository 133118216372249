/**
 * Component TreePms
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { treeItemClasses } from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import FactoryIcon from "@mui/icons-material/Factory";
import StraightenRoundedIcon from "@mui/icons-material/StraightenRounded";

import ExpandIcon from "@mui/icons-material/Expand";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";

import { red } from "@mui/material/colors";
import { lightGreen } from "@mui/material/colors";
import { orange } from "@mui/material/colors";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    "&.Mui-expanded": {
      fontWeight: theme.typography.fontWeightRegular,
    },
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    "&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: "var(--tree-view-color)",
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: "inherit",
      color: "inherit",
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const StyledTreeItem = (props) => {
  const {
    bgColor,
    color,
    colorIcon,
    labelIcon: LabelIcon,
    labelInfo,
    labelText,
    ...other
  } = props;

  return (
    <StyledTreeItemRoot
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} sx={{ mr: 1, color: colorIcon }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 0.5 }}
          >
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </Box>
      }
      style={{
        "--tree-view-color": color,
        "--tree-view-bg-color": bgColor,
      }}
      {...other}
    />
  );
};

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const TreePms = (props) => {
  const { plants, changePlant } = props;
  const [expanded, setExpanded] = useState([]);

  const handleToggle = (event, nodeIds) => {
    console.log("handleToggle Nodeids");
    console.log(nodeIds);
    setExpanded(nodeIds);
  };

  const handleSelect = (event, nodeIds) => {
    console.log("handleSelect Nodeids ");
    console.log(nodeIds);
    let parts = nodeIds.split("_");
    let url = `/dashboard/${parts[0]}/${parts[1]}`;
    console.log(url);
    if (parts[0] == "line") location.href = url;
    if (parts[0] == "plant") changePlant(parts[1]);
  };

  const handleExpandClick = () => {
    setExpanded((oldExpanded) =>
      oldExpanded.length === 0
        ? plants.map((plant) => `plant_${plant.id.toString()}`)
        : []
    );
  };

  useEffect(() => {
    console.log("Plants changed!");
    setExpanded(plants.map((plant) => `plant_${plant.id.toString()}`));
  }, [plants]);

  const getColor = (textColor) => {
    let color = red[500];
    switch (textColor) {
      case "green":
        color = lightGreen[500];
        break;
      case "orange":
        color = orange[500];
        break;
    }
    return color;
  };

  return (
    <Card
      sx={{ boxShadow: 4, minHeight: 250, maxHeight: 260, overflowY: "scroll" }}
    >
      <CardContent sx={{}}>
        <Stack direction="row" spacing={0} alignItems="flex-start">
          <TreeView
            aria-label="PMS"
            expanded={expanded}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
            defaultCollapseIcon={<ArrowDropDownIcon />}
            defaultExpandIcon={<ArrowRightIcon />}
            defaultEndIcon={<div style={{ width: 20 }} />}
            sx={{
              height: 160,
              flexGrow: 1,
              maxWidth: 280,
              overflowY: "visible",
            }}
          >
            {plants.map((plant) => (
              <StyledTreeItem
                key={plant.id}
                nodeId={`plant_${plant.id}`}
                labelText={plant.name}
                labelIcon={FactoryIcon}
                colorIcon={getColor(plant.state_color)}
                color="#1a73e8"
                bgColor="#e8f0fe"
              >
                {plant.active_lines.map((line) => (
                  <StyledTreeItem
                    key={line.id}
                    nodeId={`line_${line.id}`}
                    labelText={line.name}
                    labelIcon={StraightenRoundedIcon}
                    labelInfo=""
                    colorIcon={getColor(line.state_color)}
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                  />
                ))}
              </StyledTreeItem>
            ))}
          </TreeView>
          <Tooltip title="Expandir / Contraer">
            <IconButton
              color="primary"
              aria-label="Exp"
              onClick={() => {
                handleExpandClick();
              }}
            >
              <ExpandIcon />
            </IconButton>
          </Tooltip>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default TreePms;
