/**
 * Component Plant Header
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";

import MultiColumn from "../charts/multi_column";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";

const PlantKpiHistoric = (props) => {
  const { plant, update } = props;

  const [typeKpi, setTypeKpi] = React.useState("oee");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    console.log("Cierre Menu");
  };
  const handleClickMenuItem = (event) => {
    setAnchorEl(null);
    let newTypeKpi = event.target.getAttribute("value");
    console.log(newTypeKpi);
    setTypeKpi(newTypeKpi);
  };

  let title_card = "";

  switch (typeKpi) {
    case "oee":
      title_card = "OEE";
      break;
    case "availability":
      title_card = "Disponibilidad";
      break;
    case "performance":
      title_card = "Rendimiento";
      break;
    case "quality":
      title_card = "Calidad";
      break;
  }

  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardContent sx={{ minHeight: 210 }}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography gutterBottom variant="h6" component="div">
            {plant.name} - Histórico {title_card}
          </Typography>
          <Tooltip title="Seleccione indicador">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open_menu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem value="oee" onClick={handleClickMenuItem}>
              OEE
            </MenuItem>
            <MenuItem value="availability" onClick={handleClickMenuItem}>
              Disponibilidad
            </MenuItem>
            <MenuItem value="performance" onClick={handleClickMenuItem}>
              Rendimiento
            </MenuItem>
            <MenuItem value="quality" onClick={handleClickMenuItem}>
              Calidad
            </MenuItem>
          </Menu>
        </Stack>

        <MultiColumn
          plant={plant}
          typeKpi={typeKpi}
          height={120}
          update={update}
        />
      </CardContent>
    </Card>
  );
};

export default PlantKpiHistoric;
