// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import Skeleton from "@mui/material/Skeleton";
import HeaderInspection from "./header_inspection.js";
import MenuNewInspection from "./menu_new_inspection.js";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import "../../styles/menu.css";
import "../../styles/modal.css";
import "../../styles/inspection_form/tab.css";
import {
  WSRequestGetForm,
  WSRequestUpdateNumberOfInsepctions,
  WSRequestDelete
} from "../../utils/inspection_form_api";
import "../../styles/lateral_menu_form.css";
import ModalDeleteForm from "./modal_delete_form.js";
import LateralMenuSection from "./lateral_menu_section.js";
import FormSection from "./form_section.js";
import Modal from "./modal.js";

class InspectionForm extends Component {
  state = {
    active_section: null,
    loading_form: true,
    error_form: false,
    form: {},
    answers: {},
    oldValues: {},

    loading_section_save: false,

    //Lateral sections
    is_extendend: true,
    //Edit
    old_section: undefined,
    old_inputs: {},
    edit_state: false,

    active_inspection: 1,
    display_model: "none",
    loading_delete: false,
    msg_delete: "",

    changes: new Set(),
    openModalContinueEdit: false,
    candidate_section_id: null,

    success_save: false,
    error_save: false
  };

  /**
   * Cambia la seccion actual del formulario
   * @param {Object} e
   * @param {String} id
   */
  setCurrentSection = (e, id) => {
    if (this.state.changes.size > 0) {
      this.setOpen(true);
      this.setState({ candidate_section_id: id });
      return;
    }
    this.setState({
      active_section: id
    });
  };

  goToSectionAndDeleteChanges = () => {
    const old_answers = this.revert_answers();
    this.setOpen(false);
    this.setState({
      active_section: this.state.candidate_section_id,
      changes: new Set(),
      candidate_section_id: null,
      answers: old_answers
    });
  };

  /**
   * Restablece valores iniciales en formulario
   */
  revert_answers = () => {
    var current_answers = this.state.answers;
    const old_answers = this.state.oldValues;
    Array.from(this.state.changes).forEach(key => {
      current_answers[key] = old_answers[key];
    });
    return current_answers;
  };

  async componentDidMount() {
    await this.getForm(
      this.props.code,
      this.props.type_response,
      this.props.real_form_id
    );
  }

  getForm = async (code_form, type_response, real_form_id) => {
    this.setState({ loading_form: true });
    try {
      let url = this.props.url_init;
      url += "/api/v4/inspection_form/form?code=" + code_form;
      url += "&type_response=" + type_response;
      url += "&id=" + real_form_id;
      url += "&order=" + this.props.production_order;
      url += "&inspector=" + this.props.inspector;
      const res = await WSRequestGetForm(url);
      const result = res.data;
      if (result.status) {
        const current_section = result.info.sections[0].id;
        const values = this.get_fields_values(result.info);
        this.setState({
          form: result.info,
          loading_form: false,
          active_section: current_section,
          answers: values,
          oldValues: { ...values }
        });
      } else {
        this.setState({ error_form: true });
      }
    } catch (error) {
      this.setState({ error_form: true });
    }
  };

  get_fields_values = form => {
    console.log(form);
    const fields = form.fields;
    console.log(fields);
    var ans = {};
    fields.forEach(field => {
      const key = this.buildKey(field);
      var val = this.state.answers[key] ? this.state.answers[key] : "";
      val = field.value ? field.value : val;
      ans[key] = val;
    });
    return ans;
  };

  buildKey = field => {
    return (
      field.id +
      "_" +
      field.inspection +
      "_" +
      field.sub_section_id +
      "_" +
      field.section_id
    );
  };

  /**
   * Setea nuevo valor de campo de formulario
   * @param {String} id
   * @param {String} value
   */
  setNewValueToField = (id, value) => {
    const current_values = this.state.answers;
    current_values[id] = value;
    this.setState({ answers: current_values });
  };

  /**
   * Setea nuevo estado
   * @param {Object} body
   */
  setNewState = body => {
    this.setState(body);
  };

  getSpecificSection = () => {
    if (this.state.form.sections) {
      const section_id = this.state.active_section;
      return this.state.form.sections.filter(
        section => section.id === section_id
      )[0];
    } else {
      return undefined;
    }
  };

  getSubSections = section_id => {
    return this.state.form.sub_sections.filter(
      sub_section => sub_section.section_id === section_id
    );
  };

  getFields = (section_id, sub_section_id) => {
    return this.state.form.fields.filter(
      field =>
        field.sub_section_id === sub_section_id &&
        field.section_id === section_id
    );
  };

  getNumOfResponseBySection = (current_section, fields_filter) => {
    if (current_section) {
      var total = this.getTotalOfResponseBySection(
        current_section,
        fields_filter
      );
      var ans = 0;
      const current_answers = this.state.answers;
      const sub_sections = this.getSubSections(current_section.id);
      sub_sections.forEach(sub_section => {
        const fields = fields_filter(current_section.id, sub_section.id);
        fields.forEach(field => {
          if (current_answers[this.buildKey(field)] !== "") {
            ans += 1;
          }
        });
      });
      return ((ans / total) * 100).toFixed(0);
    }
    return 0;
  };

  getTotalOfResponseBySection = (current_section, fields_filter) => {
    if (current_section) {
      var total = 0;
      const sub_sections = this.getSubSections(current_section.id);
      sub_sections.forEach(sub_section => {
        const fields = fields_filter(current_section.id, sub_section.id);
        total += fields.length;
      });
      return total;
    }
    return 0;
  };

  buildParams = dict_values => {
    return {
      form: this.props.code,
      section: this.state.active_section,
      order: this.props.production_order,
      inspector: this.props.inspector,
      type: this.props.type_response,
      real_form_id: this.props.real_form_id,
      inspection_id: this.state.active_inspection,
      fields: dict_values
    };
  };

  /**
   * Actualiza informacion del formulario
   * @param {Object} data
   * @param {Object} new_form
   */
  updateNewData = (data, new_form) => {
    const values = this.get_fields_values(new_form);
    this.setState({
      form: new_form,
      loading_section_save: false,
      edit_state: false,
      old_section: undefined,
      old_inputs: {},
      changes: new Set(),
      answers: { ...values },
      oldValues: { ...values }
    });
    this.props.setFormSaved({
      type_response: "OLD",
      real_form_id: data.real_form_id
    });
  };

  replace_key_value_section = (sections, ready_section, key, val) => {
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].id === ready_section.id) {
        sections[i][key] = val;
        return sections;
      }
    }
    return sections;
  };

  openNav = () => {
    this.setState({ is_extendend: !this.state.is_extendend });
  };
  goToResume = () => {
    window.location = "/quality/develop";
  };

  /**
   * Marca el estado actual con la espera de guardado
   */
  setLoadingSave = () => {
    this.setState({ loading_section_save: true });
  };

  /**
   * Marca el estado actual con errores al guardar seccion
   */
  setSaveWithErrors = () => {
    this.setState({ error_save: true, loading_section_save: false });
  };

  addNewInspection = async () => {
    const params = {
      form: this.props.code,
      order: this.props.production_order,
      inspector: this.props.inspector,
      type: this.props.type_response,
      real_form_id: this.props.real_form_id
    };
    this.setState({ loading_section_save: true });
    try {
      const url =
        this.props.url_init +
        "/api/v4/inspection_form/update_number_of_inspections";
      const res = await WSRequestUpdateNumberOfInsepctions(url, params);
      const result = res.data;
      this.updateDataNumberOfInspections(result.info);
    } catch (err) {
      this.setState({ error_save: true, loading_section_save: false });
    }
  };

  updateDataNumberOfInspections = data => {
    var new_info_form = data.inspection_form;
    var new_fields = this.state.form.fields.concat(data.new_fields);
    var form = this.state.form;
    form["inspection_form"] = new_info_form;
    form["fields"] = new_fields;
    const values = this.get_fields_values(form);
    console.log("NEW FORM");
    console.log(form);
    this.setState({
      form: form,
      answers: values,
      loading_section_save: false
    });
    this.props.updateInfoCurrentForm(new_info_form.id);
  };

  setCurrentInspection = num => {
    if (this.state.edit_state) {
      this.cancelEditState();
    }
    this.setState({ active_inspection: num });
  };

  openModal = () => {
    this.setState({ display_model: "block" });
  };

  closeModal = () => {
    this.setState({ display_model: "none" });
  };

  goToDelete = async () => {
    const form_id = this.state.form.inspection_form.id;
    this.setState({ loading_delete: true, msg_delete: "" });
    try {
      const url = "/api/v4/inspection_form/delete_form?form_id=" + form_id;
      const res = await WSRequestDelete(url);
      const result = res.data;
      if (result.status) {
        this.goToResume();
      } else {
        this.setState({
          loading_old_forms: false,
          msg_delete: "ERROR"
        });
      }
    } catch (error) {
      this.setState({
        loading_old_forms: false,
        msg_delete: "ERROR"
      });
    }
  };

  setOpen = val => {
    this.setState({ openModalContinueEdit: val });
  };

  /**
   * Cierra alerta de guardado exitoso
   */
  closeAlertSuccess = () => {
    console.log('closeAlertSuccess');
    this.setState({success_save: false});
  }

  openAlertSuccess = () => {
    console.log('closeAlertSuccess');
    this.setState({success_save: true});
  }
  
  render() {
    const class_lateral = this.state.is_extendend ? "extended" : "nothing";
    const class_form = this.state.is_extendend ? "extended" : "margin_left";
    const size_form = this.state.is_extendend ? "4" : "6";
    const size_form_desktop = this.state.is_extendend ? "8" : "11";
    const size_lateral = "4";
    const current_section = this.getSpecificSection();
    return (
      <div>
        {this.state.loading_form ? (
          <Skeleton variant="rectangular" width={550} height={550} />
        ) : (
          <div className="mdl-grid">
            <div
              className={
                "mdl-cell mdl-cell--" +
                size_lateral +
                "-col graybox " +
                class_lateral
              }
            >
              <button
                className="mdl-button mdl-button--colored mdl-js-button mdl-button--raised"
                onClick={() => this.goToResume()}
                style={{ margin: "10px" }}
                disabled={this.state.loading_delete}
              >
                Volver
              </button>
              {this.state.form.inspection_form.id != "null" &&
              !this.state.form.inspection_form.is_complete ? (
                !this.state.loading_delete ? (
                  <button
                    className="mdl-button mdl-js-button mdl-button--raised"
                    onClick={() => this.openModal(this.state.form)}
                    style={{ margin: "10px" }}
                  >
                    Eliminar
                  </button>
                ) : (
                  "Cargando..."
                )
              ) : null}
              <LateralMenuSection
                form={this.state.form}
                active_section={this.state.active_section}
                setCurrentSection={this.setCurrentSection}
                getNumOfResponseBySection={this.getNumOfResponseBySection}
                getFields={this.getFields}
                getClassSection={this.getClassSection}
              />
            </div>
            <div
              className={
                "mdl-cell mdl-cell--" +
                size_form_desktop +
                "-col-desktop mdl-cell--" +
                size_form +
                "-col mdl-cell--4-col-phone graybox border_form " +
                class_form
              }
            >
              <span
                style={{ fontSize: "30px", cursor: "pointer" }}
                onClick={() => this.openNav()}
              >
                &#9776;
              </span>
              {this.state.success_save ? (
                <Alert 
                  severity="success" 
                  onClose={() => this.closeAlertSuccess()}
                >
                  Se han guardado los datos correctamente.
                </Alert>
              ) : (
                null
              )}
              {this.state.error_save ? (
                <Alert severity="error">
                  Ocurrió un error. Intente guardar nuevamente.
                </Alert>
              ) : (
                null
              )}{" "}
              <HeaderInspection form={this.state.form.inspection_form} />
              <MenuNewInspection
                form={this.state.form.inspection_form}
                active_inspection={this.state.active_inspection}
                setCurrentInspection={this.setCurrentInspection}
                addNewInspection={this.addNewInspection}
              />
              <FormSection
                form={this.state.form}
                url_init={this.props.url_init}
                active_inspection={this.state.active_inspection}
                answers={this.state.answers}
                oldValues={this.state.oldValues}
                loading_section_save={this.state.loading_section_save}
                edit_state={this.state.edit_state}
                old_section={this.state.old_section}
                old_inputs={this.state.old_inputs}
                changes={this.state.changes}
                currentSection={current_section}
                getNumOfResponseBySection={this.getNumOfResponseBySection}
                buildKey={this.buildKey}
                updateNewData={this.updateNewData}
                buildParams={this.buildParams}
                setLoadingSave={this.setLoadingSave}
                setSaveWithErrors={this.setSaveWithErrors}
                getSubSections={this.getSubSections}
                getFields={this.getFields}
                setNewValueToField={this.setNewValueToField}
                setNewState={this.setNewState}
                revert_answers={this.revert_answers}
                openAlertSuccess={this.openAlertSuccess}
              />
            </div>
            <ModalDeleteForm
              display={this.state.display_model}
              form={this.state.form.inspection_form}
              goToDelete={this.goToDelete}
              closeModal={this.closeModal}
            />
            <Modal
              open={this.state.openModalContinueEdit}
              setOpen={this.setOpen}
              setAction={this.goToSectionAndDeleteChanges}
            />
          </div>
        )}
      </div>
    );
  }
}

export default InspectionForm;
