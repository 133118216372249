// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import "../../styles/menu.css";
import "../../styles/inspection_form/tab.css";

class LateralMenuSection extends Component {
  /**
   * Entrega secciones del formulario
   * @returns {Array<Object>}
   */
  getSections = () => {
    return this.props.form.sections;
  };

  /**
   * Entrega clase actual del boton de seccion
   * @param {Object} section
   * @returns {String}
   */
  getClassSection = section => {
    if (this.isReadySectionByAllFields(section)) {
      return "ready_section";
    }
    return this.props.active_section === section.id
      ? "active_section"
      : "section_step";
  };

  /**
   * Verifica si la seccion esta lista o no.
   * Para esto compara la cantidad total de campos de la seccion (todas las inspecciones(?) )
   * @param {Object} section 
   * @returns {Boolean}
   */
  isReadySectionByAllFields = section => {
    const fields_by_section = this.props.form.fields.filter(
      field => field.section_id === section.id
    );
    const fields_are_ready = fields_by_section.filter(
      filter => filter.is_ready
    );
    return fields_by_section.length === fields_are_ready.length;
  };

  render() {
    return (
      <ul className="steps">
        {this.getSections().map((section, index) => {
          const fieldsWithValue =
            this.props.getNumOfResponseBySection(section, this.props.getFields);
          return (
            <li
              key={index}
              className={this.getClassSection(
                section,
                fieldsWithValue
              )}
            >
              <span className="caret">
                <button
                  className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent button_section"
                  onClick={e => this.props.setCurrentSection(e, section.id)}
                >
                  {index + 1}) {section.name}{" "}
                  {fieldsWithValue}%
                </button>
              </span>
            </li>
          );
        })}
      </ul>
    );
  }
}

export default LateralMenuSection;
