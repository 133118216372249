/**
 * Component Line Item
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import LineInfo from "./line_info";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Grid from "@mui/material/Unstable_Grid2";
import Gauge from "../charts/gauge";
import MultiChart from "../charts/multi_chart";

import Chronology from "../charts/chronology";
import Speed from "../charts/speed";
import Column from "../charts/column";
import OrderTimeline from "./order_timeline";

import dayjs from "dayjs";
import BasicDatePicker from "./basic_date_picker";
import { responseInfo, responseError } from "../../utils/response_console";

const LineItem = (props) => {
  const { line, update } = props;

  const [indicator, setIndicator] = useState({
    oee: 0,
    state: { name: "", color: "red" },
    shift: { name: "" },
    product: { name: "", order: "" },
    operator: { name: "" },
  });
  const [date, setDate] = React.useState(dayjs());

  const handleChangeDate = (newDate) => {
    setDate(newDate);
  };

  let color_state = { bgcolor: "#e53935", color: "#ffffff" };
  switch (indicator.state.color) {
    case "red":
      color_state = { bgcolor: "#e53935", color: "#ffffff" };
      break;
    case "green":
      color_state = { bgcolor: "#00cc00", color: "#ffffff" };
      break;
  }

  useEffect(() => {
    axios
      .get("/api/v2/dash/indicator", {
        params: { model: "line", id: line.id },
      })
      .then((response) => {
        responseInfo(response, true);
        setIndicator(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [line.id, date, update]);

  /**  */
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardContent>
        <Stack direction="row" spacing={0} justifyContent="space-between">
          <Typography gutterBottom variant="h6" component="div">
            {line.name}
          </Typography>
          <BasicDatePicker date={date} handleChange={handleChangeDate} />
        </Stack>

        <Grid container spacing={1}>
          <Grid md={9}>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Chip
                label={indicator.state.name}
                size="small"
                sx={color_state}
              />
              <Chip label={indicator.shift.name} size="small" />
              <Chip label={indicator.operator.name} size="small" />
            </Stack>
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              <Chip label={indicator.product.order} size="small" />
              <Chip label={indicator.product.name} size="small" />
            </Stack>
          </Grid>
          <Grid md={3}>
            <Gauge height="60px" size="13px" data={[indicator.oee]} />
          </Grid>
        </Grid>
        <MultiChart line={line} date={date} update={update} />
      </CardContent>
      <CardActions>
        <Button href={`line/${line.id}`} size="small">
          Detalle
        </Button>
        <Button disabled onClick={handleOpen} size="small">
          Ordenes{" "}
        </Button>
        <Button disabled size="small">
          Turnos{" "}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <OrderTimeline />
          </Box>
        </Modal>
      </CardActions>
    </Card>
  );
};

export default LineItem;
