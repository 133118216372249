/**
 * Component Multi Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-11-09
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import Chronology from "../charts/chronology";
import Speed from "../charts/speed";
import Column from "../charts/column";
import Tooltip from "@mui/material/Tooltip";
import { responseInfo, responseError } from "../../utils/response_console";

const MultiChart = (props) => {
  const { line, date, update } = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [typeChart, setTypeChart] = useState("chronology");

  const [serieProduction, setSerieProduction] = useState({
    name: "",
    data: [],
  });

  const [serieSpeed, setSerieSpeed] = useState({
    name: "",
    data: [],
  });

  const [serieChronology, setSerieChronology] = useState({
    name: "",
    data: [],
  });

  useEffect(() => {
    console.log("Get data Line %s Change to %s", line.id, typeChart);
    switch (typeChart) {
      case "chronology":
        axios
          .get("/api/v1/data/stops_day", {
            params: {
              model: "line",
              id: line.id,
              date: date.format("YYYY-MM-DD"),
            },
          })
          .then((response) => {
            responseInfo(response, true);
            setSerieChronology(response.data);
          })
          .catch((error) => {
            responseError(error);
          });
        break;
      case "speed":
        axios
          .get("/api/v1/data/speed_day", {
            params: {
              model: "line",
              id: line.id,
              date: date.format("YYYY-MM-DD"),
            },
          })
          .then((response) => {
            responseInfo(response, true);
            setSerieSpeed(response.data);
          })
          .catch((error) => {
            responseError(error);
          });
        break;
      case "production":
        axios
          .get("/api/react/v1/dashboard/data_production", {
            params: {
              model: "line",
              id: line.id,
              date: date.format("YYYY-MM-DD"),
            },
          })
          .then((response) => {
            responseInfo(response, true);
            setSerieProduction(response.data);
          })
          .catch((error) => {
            responseError(error);
          });
        break;
    }
  }, [typeChart, line.id, date, update]);

  const open_menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    console.log("Cierre Menu");
  };
  const handleClickMenuItem = (event) => {
    setAnchorEl(null);
    let newTypeChart = event.target.getAttribute("value");
    console.log(newTypeChart);
    setTypeChart(newTypeChart);
  };

  let chart = <div></div>;
  let title_chart = "";
  let height = 80;

  switch (typeChart) {
    case "chronology":
      chart = <Chronology serie={serieChronology} height={height} />;
      title_chart = "Cronología";
      break;
    case "speed":
      chart = <Speed serie={serieSpeed} height={height} />;
      title_chart = "Velocidad";
      break;
    case "production":
      chart = <Column serie={serieProduction} height={height} />;
      title_chart = "Producción";
      break;
  }

  return (
    <Container>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Typography />
        <Typography
          variant="button"
          align="center"
          color="primary"
          sx={{ mt: 1 }}
        >
          {title_chart}
        </Typography>
        <Tooltip title="Seleccione gráfico">
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>
        </Tooltip>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open_menu}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem value="chronology" onClick={handleClickMenuItem}>
            Cronología
          </MenuItem>
          <MenuItem value="speed" onClick={handleClickMenuItem}>
            Velocidad
          </MenuItem>
          <MenuItem value="production" onClick={handleClickMenuItem}>
            Producción
          </MenuItem>
        </Menu>
      </Stack>
      {chart}
    </Container>
  );
};

export default MultiChart;
