// Component Tree Dashboard
// @author: Vicente Illanes 
// @version: 19.09.2022
import React  from 'react';
import Grid from '@mui/material/Grid';
import { TreeLinesInitQuality , TreeBuildStructure, TreeBuildStatus} from "../../utils/tree_dash";
import "../../styles/spinner.css";
import "../../styles/quality.css";



class TreeDashboard extends React.Component {  

    state = {
        display: true
    }

    async componentDidMount(){
        TreeLinesInitQuality(URL_INIT, this.TreeStructureDataQuality);
    }

    TreeStructureDataQuality = (data) => {
        TreeBuildStructure(data);
        TreeBuildStatus();
        this.TreeBuildEventNode();        
    }

    TreeBuildEventNode = () => {
        $("#tree").on("select_node.jstree", (evt, data) => {
            console.log(data);
            const node_id = data.node.id;
            // CURRENT_MODEL = split_array[0];
            // CURRENT_ID = split_array[1];
            // Util.log("Modelo "+CURRENT_MODEL+" id "+CURRENT_ID);
            this.props.selectFilterTree(node_id);
        });
    }

    /* 
    * Cambia si el arbol esta mostrandose o no
    */
    setDisplay = () => {
        this.setState({display: !this.state.display});
    }

    render () {
        return(         
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <div className="card-square mdl-card mdl-shadow--2dp" style={{"minHeight": "50px"}}>
                    <div className="mdl-card__title"> 
                        <h1 className="mdl-card__title-text">
                            {this.props.title}
                        </h1>
                        <a href="#" id="button_slide_tree" className="button_slide_orders" onClick={() => this.setDisplay()}>
                            <i className="pms-i compress gray title_order"></i>
                        </a>
                        <span htmlFor="button_slide_tree" className="mdl-tooltip mdl-tooltip--left">
                            {this.props.button_description}
                        </span>
                    </div>
                    <div className={"mdl-card--expand " + (this.state.display ? "display_true" : "display_none")}>
                        <div id="tree" className="tree_dash" ></div>
                    </div>
                </div>
            </Grid>
        );
    }            
}

export default TreeDashboard;