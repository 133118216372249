// Component Header
// @author: Vicente Illanes 
// @version: 10.05.2022
// import React, { useState } from 'react';
import React  from 'react';
import "../../styles/spinner.css";
import "../../styles/quality.css";



class HighchartChronology extends React.Component {  
        
    async componentDidMount(){
        var chart = Highchart_ChronologyInit("highchart_chronology_" +this.props.order.id, "");
        var item = { 
            x:1000,
            y:1,
            z: 1000, 
            status: "Creado", 
            reasons:"reasons",
            color: '#40EF0A', 
            description:"",
          }
        chart.addSeries([item, item, item]);
    }

    render () {
        return(         
            <div id={"highchart_chronology_" +this.props.order.id}>
            </div> 
        );
    }            
}


export default HighchartChronology;


