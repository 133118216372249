/**
 * Component Multi Column
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */

import React, { useEffect, useState } from "react";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Gauge from "../charts/gauge";
import Column from "../charts/column";
import Tooltip from "@mui/material/Tooltip";
import { responseInfo, responseError } from "../../utils/response_console";

const MultiColumn = (props) => {
  const { plant, typeKpi, height = 120, update } = props;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [typeChart, setTypeChart] = React.useState("last_12m");

  const [data, setData] = useState({
    average: 0,
    serie: {
      name: "",
      data: [],
    },
  });

  const open_menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    console.log("Cierre Menu");
  };
  const handleClickMenuItem = (event) => {
    setAnchorEl(null);
    let newTypeChart = event.target.getAttribute("value");
    console.log(newTypeChart);
    setTypeChart(newTypeChart);
  };

  const roundValue = (num) => {
    return Math.round(num * 10) / 10;
  };

  useEffect(() => {
    axios
      .get("/api/react/v1/dashboard/data_plant_historic", {
        params: { id: plant.id, kpi: typeKpi, time: typeChart },
      })
      .then((response) => {
        responseInfo(response, true);
        setData(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [plant.id, typeKpi, typeChart, update]);

  const listOptionsChart = [
    { name: "Últimos 12 meses", code: "last_12m" },
    { name: "Año actual por mes", code: "year" },
    { name: "Mes actual por semana", code: "month_week" },
    { name: "Mes actual por día", code: "month" },
    { name: "Semana actual por día", code: "week" },
  ];

  let [option] = listOptionsChart.filter((item) => item.code == typeChart);
  let title_chart = option.name;

  return (
    <Grid container spacing={1}>
      <Grid xs={3} md={3}>
        <Gauge
          title="Promedio"
          height="160px"
          size="23px"
          data={[roundValue(data.average)]}
        />
      </Grid>
      <Grid xs={9} md={9}>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography />
          <Typography
            variant="button"
            align="center"
            color="primary"
            sx={{ mt: 1 }}
          >
            {title_chart}
          </Typography>
          <Tooltip title="Seleccione periodo">
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? "long-menu" : undefined}
              aria-expanded={open ? "true" : undefined}
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open_menu}
            onClose={handleCloseMenu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {listOptionsChart.map((item) => (
              <MenuItem
                key={item.code}
                value={item.code}
                onClick={handleClickMenuItem}
              >
                {item.name}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
        <Column serie={data.serie} height={height} unit="%" />
      </Grid>
    </Grid>
  );
};

export default MultiColumn;
