/**
 * Component Line Info
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
 import React, { useEffect, useState } from 'react';
 
 const LineInfo = (props) => {

   return (
     <div>{ props.line.name }</div>
   )
 }
 
 export default LineInfo