// Component Header
// @author: Vicente Illanes 
// @version: 10.05.2022
// import React, { useState } from 'react';
import React , { useEffect, useState } from 'react';
import "../../styles/lateral_menu_form.css";
import CardOrders from './card_orders';
import DialogOrder from './dialog_order';
import { WSDashOrderGet } from '../../apis/dash_order';
import { WSRequestGetOldForms } from "../../utils/inspection_form_api";



const  Develop = (props) =>{  
    //Fecha Actual Para Ordenes Terminadas
    const [current_date, set_current_date] = useState(new Date());
    const [CURRENT_ORDER_ID, SET_CURRENT_ORDER_ID] = useState(undefined);
    const [DIALOG_PROGRESS, SET_DIALOG_PROGRESS] = useState("0");
    const [LIST_ORDERS, SET_LIST_ORDERS] = useState([]);
    const [LIST_PLANNED, SET_LIST_PLANNED] = useState([]);
    const [LIST_RUNNING, SET_LIST_RUNNING] = useState([]);
    const [LIST_COMPLETED, SET_LIST_COMPLETED] = useState([]);
    const [CURRENT_MODEL, SET_CURRENT_MODEL] = useState('plant');
    const [CURRENT_ID, SET_CURRENT_ID] = useState("0"); //????
    const [INTERVAL_IND, SET_INTERVAL_IND] = useState(null);
    const [SHOW_DIALOG, SET_SHOW_DIALOG] = useState(false);
    const [OLD_FORMS, SET_OLD_FORMS] = useState([]);

    //Antes de Montar Componente, hay que consultar por las ordenes
    useEffect(() => {
        DashOrder_Update();        
    }, []);

    useEffect(() => {
        getOldForms(CURRENT_ORDER_ID);
    }, [CURRENT_ORDER_ID])

    const DashOrder_Update = () => {
        DashOrder_Get();
        SET_INTERVAL_IND(setInterval(DashOrder_Get, 60000));
    }

    const DashOrder_Get = async () => {
        const url = URL_INIT;
        const controller = 'api/v2/dash';
        const action = 'production_orders_quality';
        const  line_id = GetCurrentLineId();
        const data = { line_id:line_id };
        try{
            const response = await WSDashOrderGet(url,controller, action, data);
            DashOrder_Result(response.data); 
        } catch (err){
            console.log(err);
        }
    }
    const DashOrder_Result = (data) => {
        SET_LIST_ORDERS((data.planned).concat(data.running).concat(data.completed));
        SET_LIST_PLANNED(data.planned);
        SET_LIST_RUNNING(data.running);
        SET_LIST_COMPLETED(data.completed);
    }

    const GetCurrentLineId = () => {
        return CURRENT_MODEL === "plant" ? "0" : CURRENT_ID;
    }

    const DashOrder_DateLeft = () => {
        DashOrder_DateFactor(-1);
    }

    const DashOrder_DateRight = () => {
        DashOrder_DateFactor(1);
    }

    const DashOrder_DateFactor = (factor) => {
        const new_date = new Date(current_date.getTime() + (3600 * 1000 * 24 * factor));
        set_current_date(new_date);
        //DashOrder_RenderCompleted(LIST_COMPLETED);
    }

    const DashOrder_HtmlPlanned = (orders) => {
        return orders.map((order, i) => {
            return(
                <li className="mdl-list__item mdl-list__item--three-line order_item" key={i}>
                    <span className="mdl-list__item-primary-content">
                        <span>{order.line_name} N° {order.number}</span>
                        <span className="mdl-list__item-text-body">
                            <div>
                                {order.product_name}<span className="a_right">{order.target}</span>
                            </div>
                            <div>{props.received_date+' '+order.created_at}</div>
                        </span>
                    </span>
                    <span className="mdl-list__item-secondary-content">
                        <a data-order_id={order.id} data-type="PLANNED" className="button_show mdl-list__item-secondary-action" href="#" onClick={() => DashOrder_DialogOrder(order, 'PLANNED')}>
                            <i className="pms-i caret-square-right blue_pms item_order"></i>
                        </a>
                    </span>
                </li>
            )
        })
    }

    const DashOrder_SetProgress = (value) => {
        if ( value > 100 )
          value = 100;
        return value+'%';
      }

    const DashOrder_HtmlRunning = (orders) => {
        return orders.map((order, index) => {
            return (
                <li className="mdl-list__item mdl-list__item--three-line order_item" key={index}>
                    <div className="mdl-list__item-primary-content">
                        <div>
                            {order.line_name} N° {order.number}
                            <span className="a_right">{order.progress}%</span> 
                        </div>
                        <div className="progress_wrap">
                            <div id={"progress_"+order.id} className="progress_bar" style={{"width": DashOrder_SetProgress(order.progress)}}></div>
                        </div>
                        <span className="mdl-list__item-text-body">
                            <div>
                                {order.product_name}
                                <span className="a_right">{order.target}</span>
                            </div>
                            <div>
                                Inicio {order.start_at}
                            </div>
                            <div>
                                Término(E) {order.end_at} <span className="a_right">T.Restante {order.t_remaining}</span>
                            </div>
                        </span>
                    </div>
                    <span className="mdl-list__item-secondary-content">
                        <a data-order_id={order.id} data-type="RUNNING" className="button_show mdl-list__item-secondary-action" href="#" onClick={() => DashOrder_DialogOrder(order, 'RUNNING')}>
                            <i className="pms-i caret-square-right blue_pms item_order"></i>
                        </a>
                    </span>
                </li>

            )
        });
    }

    const DashOrder_HtmlCompleted = (orders) => {
        return orders.map((order, i) => {
            return (
                order.end_at.includes(yyyymmdd(current_date)) ?
                <li className="mdl-list__item mdl-list__item--three-line order_item" key={i}>
                    <span className="mdl-list__item-primary-content">
                        <span>{order.line_name} N° {order.number}</span>
                        <span className="mdl-list__item-text-body">
                            <div>
                                {order.product_name}
                                <span className="a_right">{order.target}</span>
                            </div>
                            <div>Inicio {order.start_at}</div>
                            <div>Término {order.end_at}</div>
                        </span>
                    </span>
                    <span className="mdl-list__item-secondary-content">
                        <a data-order_id={order.id} data-type="COMPLETED" className="button_show mdl-list__item-secondary-action" href="#" onClick={() => DashOrder_DialogOrder(order, 'COMPLETED')}>
                            <i className="pms-i caret-square-right blue_pms item_order"></i>
                        </a>
                    </span>
                </li> : null
            )
        });
    }

    const closeDialog = () => {
        var dialog = document.querySelector('#dialog_show_order');
        dialog.close();
    }

    const DashOrder_DialogOrder = (order, type) => {
        const order_id = order.id
        SET_CURRENT_ORDER_ID(order_id); //$('#app_vars').data('order_id', order_id);
        console.log('Show dialog order '+order_id+' '+type);
        //order = findItem(LIST_ORDERS, 'id', order_id);
        $("#order_number").text(order.number);
        $("#line_name").text(order.line_name);
        $("#product_name").text(order.product_code+' '+order.product_name);
        $("#target").text(t.indicator.target+' '+order.target);
        $("#received_date").text(t.attributes.received_date+' '+order.created_at);
        $("#progress_wrap_dialog").hide();
        switch (type) {
              case 'PLANNED':
                $('#button_delete_order').show();
                //DashOrder_SetProgress("#progress_dialog", 0);
                $("#progress").text('');
                $("#good").text('');
                $("#rejected").text('');
                $("#rework").text('');
                $("#total").text('');
                $("#running_date").text('');
                $("#start_date").text('');
                $("#end_date").text('');
                $("#remaining").text('');
                $("#sended_date").text('');
                $("#response").text('');
                $("#operator").text('');
                $("#state").text(t.model.state+' '+'Planificada');
                break;
              case 'RUNNING':
                $('#button_delete_order').hide();
                $("#progress_wrap_dialog").show();
                SET_DIALOG_PROGRESS(DashOrder_SetProgress(order.progress));
                $("#progress").text(t.system.progress+' '+order.progress+'%');
                $("#good").text(t.indicator.good+' '+order.good);
                $("#rejected").text(t.indicator.rejected+' '+order.rejected);
                $("#rework").text(t.attributes.rework+' '+order.rework);
                $("#total").text(t.indicator.total+' '+order.total);
                $("#start_date").text(t.date.start_date+' '+order.start_at);
                $("#end_date").text(t.date.end_date+'(E) '+order.end_at);
                $("#remaining").text(t.indicator.remaining+' '+order.t_remaining); 
                $("#sended_date").text('');
                $("#response").text('');
                $("#operator").text(t.user.operator+' '+order.operator_name);
                $("#state").text(t.model.state+' '+'En Ejecución');
                break;
              case 'COMPLETED':
                $('#button_delete_order').hide();
                //DashOrder_SetProgress("#progress_dialog", order.progress);
                $("#progress").text(t.system.compliance+' '+order.progress+'%');
                $("#good").text(t.indicator.good+' '+order.good);
                $("#rejected").text(t.indicator.rejected+' '+order.rejected);
                $("#rework").text(t.attributes.rework+' '+order.rework);
                $("#total").text(t.indicator.total+' '+order.total);
                $("#start_date").text(t.date.start_date+' '+order.start_at);
                $("#end_date").text(t.date.end_date+' '+order.end_at);
                $("#remaining").text(t.indicator.duration+' '+order.duration);
                $("#response").text(t.attributes.erp_response+' '+order.erp_response);
                $("#sended_date").text(t.attributes.sended_date+' '+order.sended_at);
                $("#operator").text(t.user.operator+' '+order.operator_name);
                $("#state").text(t.model.state+' '+'Terminada');
                break;
            }
        var dialog = document.querySelector('#dialog_show_order');
        dialog.showModal();
    }
   
    const getOldForms = async (order) => {
        try {
          const url = URL_INIT + "/api/v4/inspection_form/forms_by_production_order?order=" + order;
          const res = await WSRequestGetOldForms(url);
          const result = res.data;
          console.log(result);
          if (result.status){
            SET_OLD_FORMS(result.info.forms);
          } else {
            SET_OLD_FORMS([]);
          }    
        } catch (error) {
          console.log(error)
          SET_OLD_FORMS([]);
        }
    }

    return(
        <> 
            <div id="app_vars"  className="hide"></div>
            <div className="mdl-grid dash_grid">
                {/* Árbol */}
                {/* CELL */}
                {/* <div className="mdl-cell mdl-cell--2-col-desktop mdl-cell--4-col-phone mdl-cell--4-col-tablet">                    <div className="card-square mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title"> 
                            <h1 className="mdl-card__title-text">{props.system_online}</h1>
                            <a href="#" id="button_slide_tree" className="button_slide_orders">
                                <i className="pms-i compress gray title_order"></i>
                            </a>
                            <span htmlFor="button_slide_tree" className="mdl-tooltip mdl-tooltip--left">{props.button}</span>
                        </div>
                        <div className=" mdl-card--expand">
                            <div id="tree" className="tree_dash" ></div>
                        </div>
                    </div>
                </div>  */}
                {/* CELL */}
                <div className="mdl-cell mdl-cell--12-col-desktop mdl-cell--4-col-phone mdl-cell--8-col-tablet cell_grid"> {/* CELL */}
                    <div className="mdl-grid mdl-grid--nesting "> {/* GRID */}
                        <CardOrders  
                            system={props.system_planned} 
                            system_upload={props.system_upload} 
                            model_production_orders={props.model_production_orders}
                            button={props.button}
                            span_id="num_planned"
                            button_slide_id="button_slide_planned"
                            orders_id="orders_planned"
                            orders={LIST_PLANNED}
                            list_orders={DashOrder_HtmlPlanned}
                        />
                        <CardOrders  
                            system={props.system_running} 
                            button={props.button}
                            span_id="num_running"
                            button_slide_id="button_slide_running"
                            orders_id="orders_running"
                            orders={LIST_RUNNING}
                            list_orders={DashOrder_HtmlRunning}
                        />
                        <CardOrders  
                            system={props.system_finished} 
                            button={props.button}
                            current_date={yyyymmdd(current_date)}
                            DashOrder_DateLeft={DashOrder_DateLeft}
                            DashOrder_DateRight={DashOrder_DateRight}
                            span_id="num_completed"
                            button_slide_id="button_slide_completed"
                            orders_id="orders_completed"
                            previous_day={props.previous_day}
                            next_day={props.next_day}
                            button_see={props.button_see}
                            system_table={props.system_table}
                            orders={LIST_COMPLETED}
                            list_orders={DashOrder_HtmlCompleted}
                        />
                    </div> {/* GRID */}
                </div> {/* CELL */}
            </div> {/* GRID */}
            {/* <!-- DIALOG SHOW ORDER --> */}
            <DialogOrder 
                model_production_orders={props.model_production_orders}
                button_close={props.button_close}
                button_destroy={props.button_destroy}
                show={SHOW_DIALOG}
                closeDialog={closeDialog}
                progress_dialog={DIALOG_PROGRESS}
                current_order_id={CURRENT_ORDER_ID}
                current_user_id={props.current_user_id}
                pending_forms={OLD_FORMS}
            />
            
        </>
    )              
}


export default Develop;

//Dado una fecha de tipo Date, entrega fecha en formato string "YYYY-MM-DD"
export const yyyymmdd = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
  
    return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
           ].join('-');
  };


