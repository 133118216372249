import React from "react";
import TableLayout from '../layouts/table_layout';

class TableLines extends React.Component {
   

    async componentDidMount(){
      await this.props.getDataProductionOrders();
      setInterval(this.props.getDataProductionOrders, 60000)
    }
  render () {
    return(
        <div>
            <TableLayout
                loading={this.props.loading_data}
                items={this.props.current_orders}
                header={[
                  {id: "line_name", name: "Línea"},
                  {id: "number", name: "N° Orden"},
                  {id: "product_name", name: "Producto"},
                  {id: "product_code", name: "Código"},
                  {id: "target", name: "Objetivo"},
                  {id: "good", name: "Unidades Buenas"},
                  {id: "boxes", name: "Cajas"},
                  {id: "tarimas", name: "Tarimas"},
                  {id: "start_at", name: "Inicio"},
                  {id: "end_at", name: "Término Estimado"},
                  {id: "t_remaining", name: "Tiempo restante"},
                  {id: "rejected", name: "Rechazado"}

                ]} 
                msg={"Cargando Producción..."}
            />
        </div>
    );
  }
}
export default TableLines
