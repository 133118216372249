// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 10.05.2022
// import React, { useState } from 'react';
import React from "react";
import Grid from "@mui/material/Grid";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import PopperPopupState from "./popper.js";

class MenuCreationForm extends React.Component {
  /* Redirecciona a vista de un nuevo formulario
   * @param {String} code
   */
  setCodeInspection = code => {
    window.location =
      "/inspection_form/new?code=" +
      code +
      "&id=" +
      this.props.production_order.id;
  };

  render() {
    const list_of_forms = [
      { name: "Producto Terminado", code: "PRODUCT_FINISHED" },
      { name: "Línea", code: "LINE" }
    ];
    return (
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <PopperPopupState
          title="Crear Formulario"
          content={
            <div>
              {list_of_forms.map((form, i) => {
                return (
                  <a
                    key={i}
                    onClick={() => this.setCodeInspection(form.code)}
                    className="dropdown-item-mui"
                  >
                    {form.name}
                  </a>
                );
              })}
            </div>
          }
        />
      </Grid>
    );
  }
}

export default MenuCreationForm;
