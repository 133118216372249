// Component Header
// @author: Vicente Illanes 
// @version: 10.05.2022
import React, { useState } from 'react';
import "../../styles/menu.css";
import InspectionForm from './inspection_form';



const  InspectionFormControl = (props) =>{  
    const [RealFormId, setRealFormId] = useState(props.real_form_id);
    const [TypeResponse, setTypeResponse] = useState(props.type_response);
    const setFormSaved= (data)=>{
        setRealFormId(data.real_form_id);
        setTypeResponse(data.type_response);
    }

    const updateInfoCurrentForm = (form_id) => {
        setTypeResponse("OLD");
        setRealFormId(form_id)

    }
    return(
        <div>
            <InspectionForm
                code={props.code} 
                inspector={props.inspector} 
                url_init={URL_INIT}  
                production_order={props.production_order} 
                type_response={TypeResponse} 
                real_form_id={RealFormId}  
                setFormSaved={setFormSaved}
                updateInfoCurrentForm={updateInfoCurrentForm}
            />
        </div>
    )                  
    
}


export default InspectionFormControl;


