import { func } from "prop-types";
import { WSRequestGet } from "../apis/dash_order";
var interval_tree = null;


/* Envia peticion para recibir estructura actual del arbol.
* Recibe como argumento url de api y funcion para utilizar con la estructura
*/
export const TreeLinesInitQuality = (url, fun_result=TreeLines_ResultStructureData) => {
  TreeGetStructureData(url, fun_result);
  //interval_tree = setInterval(Tree_GetStatusData, UPDATE_TIME);
}

function Tree_GetStatusData() {
  var controller = 'api/v4/tree';
  var action = 'status';
  var params = { };
  WS_Request(controller, action, params, Tree_ResultStatusData);
}

async function TreeGetStructureData (url, fun_result) {
  var controller = 'api/v4/tree';
  var action = 'structure_lines';
  var params = {};
  try {
    const res = await WSRequestGet(url, controller, action, params);
    fun_result(res.data);
  } catch (error) {
    console.log(error);
  }
}


export const  TreeBuildStructure = (data) => {
  $('#tree').jstree({ 
    'plugins': [ "sort" ], 
    'sort' : function(a, b) {
      var a1 = this.get_node(a);
      var b1 = this.get_node(b);
      if (b1.data.position == null)
        return -1;
      if (a1.data.position == null)
        return 1;
      return (a1.data.position > b1.data.position) ? 1 : -1;
    },
    'core': { 'data':data } 
  });
}

export const TreeBuildStatus = () => {
  $('#tree').on('ready.jstree', function() {
    $("#tree").jstree("open_all");   
    TreeGetStatusData();
  });
}

export const TreeBuildEventNode = () => {
  $("#tree").on("select_node.jstree", function(evt, data){
    console.log(data);
    const split_array = data.node.id.split("/");
    CURRENT_MODEL = split_array[0];
    CURRENT_ID = split_array[1];
    Util.log("Model "+CURRENT_MODEL+" id "+CURRENT_ID);
  });
}


function TreeLines_ResultStructureData(data) {
  TreeBuildStructure(data);
  TreeBuildStatus();
  TreeBuildEventNode();
}

async function TreeGetStatusData() {
  var controller = 'api/v4/tree';
  var action = 'status';
  var params = {};
  try{
    const res = await WSRequestGet(URL_INIT, controller, action, params);
    Tree_ResultStatusData(res.data);
  } catch (error) {
    console.log(error);
  }
}

function Tree_ResultStatusData(data) {
  data.list.forEach(function(node) {
    var selector = '#'+node.model+'\\/'+node.model_id+'_anchor > i';
    $(selector).removeClass('gray red green blue black yellow orange');
    $(selector).addClass(node.status_color);
  });
} 

// function DashOrder_Get() {
//   controller = 'api/v2/dash';
//   action = 'production_orders';
//   if ( CURRENT_MODEL == "plant" )
//     line_id = "0";
//   else 
//     line_id = CURRENT_ID;
//   data = { line_id:line_id };
//   WS_Request (controller, action, data, DashOrder_Result);  
// }
