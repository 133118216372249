import React from 'react';
import MenuInspection from './menu_inspection';
import {Link} from 'react-router-dom';

const  DialogOrder = (props) =>{

    const goToFormPending =(id) => {
        window.location = "/inspection_form/edit?id=" + id;        
    }

    const goToDetailQuality = (order_id) => {
        window.location = "/production_orders/resume/" + order_id;
    }


    return(
        <dialog id="dialog_show_order" className="mdl-dialog dialog_order">
            <h3 className="mdl-dialog__title"> {props.model_production_orders} N°<span id="order_number"></span> </h3>
            <div className="mdl-dialog__content"> 
                <div id="progress_wrap_dialog" className="progress_wrap"><div id="progress_dialog" className="progress_bar" style={{"width": props.progress_dialog}}></div></div>
                <div id="line_name"></div>
                <div id="product_name"></div>
                <div id="target"></div>
                <div id="remaining"></div>
                <div id="progress"></div>
                <div id="total"></div>
                <div id="total_good"></div>
                <div id="good"></div>
                <div id="rejected"></div>
                <div id="rework"></div>
                <div id="test"></div>
                <div id="received_date"></div>
                <div id="running_date"></div>
                <div id="start_date"></div>
                <div id="end_date"></div>    
                <div id="sended_date"></div>
                <div id="response"></div>
                <div id="operator"></div>
                <div id="state"></div>
            </div>
            <hr/>
            <div className="mdl-grid">
                <div className="mdl-cell mdl-cell--4-col graybox">
                    <h3 className="mdl-dialog__title"> Calidad  </h3>
                </div>
                <div className="mdl-cell mdl-cell--8-col graybox">
                    <MenuInspection
                            production_order={props.current_order_id}
                            current_user={props.current_user_id}
                            data={false}
                    />
                </div>
                <div className="mdl-cell mdl-cell--12-col graybox">
                    <div className="mdl-dialog__actions">
                        <div>
                            <p>
                                <a href="#" onClick={() => goToDetailQuality(props.current_order_id)}>
                                    Detalle Calidad
                                </a>
                            </p>
                            {" "}
                            {props.pending_forms.length > 0 ?
                            <a href="#" onClick={() => goToFormPending(props.pending_forms[0].id)}>
                                Continuar último formulario
                            </a> : null}
                            {/* <ul style={{"margin": 0, "padding": 0, "fontSize": "13px"}}>
                                {props.pending_forms.map((pending_form, i) => {
                                    return (
                                        !pending_form.is_ready ? <li key={i}>
                                            <a href="#" onClick={() => goToFormPending(pending_form.id)}>
                                                Completar {pending_form.name} (REV. {pending_form.revision}) ({pending_form.porcentage}%)
                                            </a>
                                        </li> : null
                                    )
                                })}

                            </ul>                 */}
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div className="mdl-dialog__actions">
                <button id="button_cancel_show_order" type="button" onClick={props.closeDialog} className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect">{props.button_close}</button>
                <button id="button_delete_order" type="button" className="mdl-button mdl-button--accent  mdl-js-button mdl-js-ripple-effect">{props.button_destroy}</button>
            </div>
        </dialog>

    )
}

export default DialogOrder;