import axios from "axios";

export const WSDashOrderGet = async (url, controller, action, data) => {
    return await WSRequestGet(url, controller, action, data)
}

export const WSRequestGet = async (url, controller, action, data) => {
    const url_init = url + "/" + controller + "/" + action;
    console.log(url_init);
    return await axios({
        method: 'get',
        url: url_init,
        withCredentials: false,
        data: data
    });
}
