/**
 * Component Plant Header
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import dayjs from "dayjs";

import Gauge from "../charts/gauge";
import BasicDatePicker from "./basic_date_picker";
import Column from "../charts/column";
import { responseInfo, responseError } from "../../utils/response_console";

const PlantKpiDay = (props) => {
  const { plant, update } = props;
  const [data, setData] = useState({
    oee: 0,
    availability: 0,
    performance: 0,
    quality: 0,
  });
  const [serieProduction, setSerieProduction] = useState({
    name: "",
    data: [],
  });
  const [date, setDate] = React.useState(dayjs());

  const handleChangeDate = (newDate) => {
    setDate(newDate);
  };

  const roundValue = (num) => {
    return Math.round(num * 10) / 10;
  };

  useEffect(() => {
    axios
      .get("/api/react/v1/dashboard/data_plant", {
        params: { id: plant.id, date: date.format("YYYY-MM-DD") },
      })
      .then((response) => {
        responseInfo(response, true);
        setData(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [plant.id, date, update]);

  useEffect(() => {
    axios
      .get("/api/react/v1/dashboard/data_production", {
        params: {
          model: "plant",
          id: plant.id,
          date: date.format("YYYY-MM-DD"),
        },
      })
      .then((response) => {
        responseInfo(response, true);
        setSerieProduction(response.data);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [plant.id, date, update]);

  return (
    <Card sx={{ boxShadow: 4 }}>
      <CardContent sx={{ minHeight: 210 }}>
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <Typography gutterBottom variant="h6" component="div">
            {plant.name} - Indicadores Día
          </Typography>
          <BasicDatePicker date={date} handleChange={handleChangeDate} />
        </Stack>
        <Grid container spacing={1}>
          <Grid xs={4} md={2}>
            <Gauge
              title="OEE"
              height="160px"
              size="22px"
              titleSize="14px"
              data={[roundValue(data.oee)]}
            />
          </Grid>
          <Grid xs={8} container md={6}>
            <Grid xs={4} md={4}>
              <Gauge
                title="Disponibilidad"
                height="130px"
                size="16px"
                titleSize="12px"
                data={[roundValue(data.availability)]}
              />
            </Grid>
            <Grid xs={4} md={4}>
              <Gauge
                title="Rendimiento"
                height="130px"
                size="16px"
                titleSize="12px"
                data={[roundValue(data.performance)]}
              />
            </Grid>
            <Grid xs={4} md={4}>
              <Gauge
                title="Calidad"
                height="130px"
                size="16px"
                titleSize="12px"
                data={[roundValue(data.quality)]}
              />
            </Grid>
          </Grid>
          <Grid xs={12} md={4}>
            <Column
              title="Producción por hora"
              titleSize="12px"
              serie={serieProduction}
              height={150}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default PlantKpiDay;
