// Component Header
// @author: Vicente Illanes
// @version: 10.05.2022
import React, { Component } from "react";
import Button from "@mui/material/Button";
import "../../styles/menu.css";
import "../../styles/inspection_form/tab.css";
import { WSRequestSaveSection } from "../../utils/inspection_form_api";

class FormSection extends Component {
  /**
   * Verifica si hay cambios o no en la seccion
   * @returns {Boolean}
   */
  notChanges = () => {
    return this.props.changes.size === 0;
  };

  /**
   * Entrega los campos filtrados por seccion, subseccion e inspeccion activa
   * @param {String} section_id
   * @param {String} sub_section_id
   * @returns {Array<Object>}
   */
  getFieldsByInspection = (section_id, sub_section_id) => {
    var active_inspection = this.props.active_inspection;
    const sub_section = this.getSubSection(sub_section_id);
    if (!sub_section.is_repeatable) {
      active_inspection = 1;
    }
    return this.props.form.fields.filter(
      field =>
        field.sub_section_id === sub_section_id &&
        field.section_id === section_id &&
        field.inspection === active_inspection
    );
  };

  /**
   * Entrega subseccion asociada por id
   * @param {String} sub_section_id
   * @returns {Object}
   */
  getSubSection = sub_section_id => {
    return this.props.form.sub_sections.filter(
      sub_section => sub_section.id === sub_section_id
    )[0];
  };

  /**
   * Prepara valores que se enviaran a peticion de guardado
   * @param {Array<Object} form_data
   * @returns {Object}
   */
  buildInputValues = fields => {
    let dict_values = {};
    fields.map(field => {
      let key_field = this.props.buildKey(field);
      console.log(field);
      let value = this.props.answers[key_field];
      dict_values[field.id] = value;
    });
    return dict_values;
  };

  sectionIsReadyByInspection(section) {
    const current_inspection = this.props.active_inspection;
    const fields_by_section = this.props.form.fields.filter(
      field => field.section_id === section.id
    );
    const fields_by_inspection = fields_by_section.filter(
      field => field.inspection === current_inspection
    );
    const fields_are_ready = fields_by_inspection.filter(
      filter => filter.is_ready
    );
    return fields_by_inspection.length === fields_are_ready.length;
  }

  /**
   * Obtiene todos los campos de una seccion
   * @returns {Array<Object>}
   */
  getAllFieldsSection() {
    let ans = [];
    this.props
      .getSubSections(this.props.currentSection.id)
      .forEach((sub_section, i) => {
        ans = ans.concat(
          this.props.getFields(this.props.currentSection.id, sub_section.id)
        );
      });
    return ans.filter(
      field => field.inspection === this.props.active_inspection
    );
  }
  /**
   * Prepara petición para guardar campos de una sección
   * @param {Object} e
   */
  saveDataSection = e => {
    e.preventDefault();
    const inputs = this.getAllFieldsSection();
    console.log(inputs);
    var dict_values = this.buildInputValues(inputs);
    const params = this.props.buildParams(dict_values);
    console.log(params);
    console.log("===");
    this.sendDataSection(params);
  };

  /**
   * Indica que la seccion no esta todavia lista
   * @param {Array<Object>} sections
   * @param {Object} section
   * @returns
   */
  notReadySection = (sections, section) => {
    return this.replace_key_value_section(sections, section, "is_ready", false);
  };

  /**
   * Reemplaza valor de una llave especifica en una seccion
   * @param {Array<Object>} sections
   * @param {Object} section
   * @param {String} key
   * @param {String} val
   * @returns
   */
  replace_key_value_section = (sections, section, key, val) => {
    for (var i = 0; i < sections.length; i++) {
      if (sections[i].id === section.id) {
        sections[i][key] = val;
        return sections;
      }
    }
    return sections;
  };

  /**
   * Indica que la seccion no esta terminada
   * @param {Object} section
   */
  NotReadyFieldsBySection = section => {
    this.statusSectionFields(section, false);
  };

  /**
   * Setea un estado en una seccion de una inspeccion especifica
   * @param {Object} section
   * @param {Boolean} status
   */
  statusSectionFields = (section, status) => {
    const current_inspection = this.props.active_inspection;
    return this.props.form.fields.map(field => {
      const new_field = { ...field };
      if (
        field.section_id === section.id &&
        field.inspection === current_inspection
      ) {
        new_field.is_ready = status;
      }
      return new_field;
    });
  };

  /**
   * Envia a guardar valores de campos en una sección
   * @param {object} params
   */
  sendDataSection = async params => {
    this.props.setLoadingSave();
    try {
      const url = this.props.url_init + "/api/v4/inspection_form/save_section";
      const res = await WSRequestSaveSection(url, params);
      const result = res.data;
      this.updateDataSection(result.info);
    } catch (err) {
      this.props.setSaveWithErrors();
    }
  };

  /**
   * Actualiza información de la sección al actualizar campos
   * @returns
   */
  updateDataSection = data => {
    var new_form = this.props.form;
    new_form["fields"] = data.fields;
    new_form["inspection_form"] = data.inspection_form;
    this.props.updateNewData(data, new_form);
    this.props.openAlertSuccess();
  };

  /**
   * Captura cancelacion de una edicion
   * @param {Object} e
   */
  goToReadyStateSection = e => {
    e.preventDefault();
    this.cancelEditState();
  };

  /**
   * Reestablece Valores de las seccion de formulario
   */
  cancelEditState = () => {
    var new_form = this.props.form;
    new_form["sections"] = this.replace_ready_section(
      this.props.form.sections,
      this.props.currentSection
    );
    new_form["fields"] = this.AreReadyFieldsBySection(
      this.props.currentSection
    );
    const old_answers = this.props.revert_answers();
    const body = {
      edit_state: false,
      form: new_form,
      old_section: undefined,
      old_inputs: {},
      answers: old_answers,
      changes: new Set()
    };
    this.props.setNewState(body);
  };

  /**
   *
   * @param {Object} section
   * @returns
   */
  AreReadyFieldsBySection = section => {
    return this.statusSectionFields(section, true);
  };

  replace_ready_section = (sections, ready_section) => {
    return this.replace_key_value_section(
      sections,
      ready_section,
      "is_ready",
      true
    );
  };

  /**
   * Retorna el tipo de input que se necesita
   * @param {Object} type
   * @returns {String}
   */
  getTypeField = type => {
    const code = type.code;
    switch (code) {
      case "TIME":
        return "time";
      case "DATE":
        return "date";
      case "LARGE TEXT":
        return "textarea";
      case "INTEGER":
        return "number";
      case "TEXT":
      default:
        return "text";
    }
  };

  /**
   * Entrega elemento html input para un campo especifico
   * @param {Object} field
   * @returns {HTMLInputElement}
   */
  getFieldInput(field) {
    const type = this.getTypeField(field.type);
    const field_id = this.props.buildKey(field);
    if (type !== "textarea") {
      return (
        <input
          type={type}
          required
          id={field.id}
          name={"field_" + field.id}
          size="15"
          value={this.props.answers[field_id]}
          onChange={e => this.set_field_value(field_id, e)}
        />
      );
    } else {
      return (
        <textarea
          id={field.id}
          name={"field_" + field.id}
          cols="80"
          rows="20"
          value={this.props.answers[field_id]}
          onChange={e => this.set_field_value(field_id, e)}
        />
      );
    }
  }

  /**
   * Envia evento de cambio de valor de un campo en particular
   * @param {String} id
   *"" @param {Object} event
   */
  set_field_value = (id, event) => {
    const oldValue = this.props.oldValues[id];
    const value = event.target.value;
    this.verificationChanges(oldValue, value, id);
    this.props.setNewValueToField(id, value);
  };

  verificationChanges = (oldValue, value, id) => {
    if (oldValue !== value) {
      this.addChange(id);
    } else {
      this.removeChange(id);
    }
  };

  /**
   * Agrega un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  addChange(id) {
    let changes = this.props.changes;
    changes.add(id);
    this.props.setNewState({ changes: changes });
  }

  /**
   * Quita un nuevo cambio al listado de cambios realizados
   * @param {String} id
   */
  removeChange(id) {
    let changes = this.props.changes;
    changes.delete(id);
    this.props.setNewState({ changes: changes });
  }

  render() {
    const fieldWithValues = this.props.getNumOfResponseBySection(
      this.props.currentSection,
      this.getFieldsByInspection
    );
    return (
      <>
        <div className="page-header" style={{ textAlign: "center" }}>
          <h5 style={{ textAlign: "center" }}>
            Sección: {this.props.currentSection.name}
          </h5>
          {fieldWithValues}%
          <progress id="file" value={fieldWithValues} max="100"></progress>
        </div>
        <div>
          <form>
            {this.props
              .getSubSections(this.props.currentSection.id)
              .map((sub_section, i) => {
                return (
                  <div className="subsection" key={i}>
                    {sub_section.code.split("_")[0] !== "EMPTY" ? (
                      <h6>{sub_section.name}</h6>
                    ) : (
                      <hr />
                    )}
                    <div className="mdl-grid">
                      {this.props
                        .getFields(this.props.currentSection.id, sub_section.id)
                        .map((field, j) => {
                          return field.inspection ===
                            this.props.active_inspection ||
                            !sub_section.is_repeatable ? (
                            <div
                              className="mdl-cell mdl-cell--4-col graybox"
                              key={j}
                            >
                              <label htmlFor={field.id}>{field.name}:</label>
                              <br />
                              {this.getFieldInput(field)}
                              <br />
                            </div>
                          ) : null;
                        })}
                    </div>
                  </div>
                );
              })}
            <hr />
            <Button
              variant="contained"
              disabled={this.notChanges() || this.props.loading_section_save}
              onClick={e => this.saveDataSection(e)}
              sx={{ margin: '2px' }}
            >
              Guardar
            </Button>
            <Button
              variant="outlined"
              disabled={this.notChanges() || this.props.loading_section_save}
              onClick={e => this.goToReadyStateSection(e)}
              sx={{ margin: '2px' }}
            >
              Restablecer
            </Button>
          </form>
        </div>
      </>
    );
  }
}

export default FormSection;
