import axios from "axios";

export const WSRequestGetForm = async (url_init) => {
    return await WSRequestGet(url_init)
}

export const WSRequestGetOldForms = async (url_init) => {
    return await WSRequestGet(url_init)
}


export const WSRequestSaveSection = async (url_init, params) => {
    return await WSRequestPost(url_init, params);
}

export const WSRequestUpdateNumberOfInsepctions = async (url_init, params) => {
    return await WSRequestPost(url_init, params);
}

const WSRequestGet = async (url_init) => {
    console.log(url_init);
    return await axios({
        method: 'get',
        url: url_init
    });
}

export const WSRequestDelete = async (url) => {
    console.log(url);
    return await axios({
        method: 'delete',
        url: url
    })
}

const WSRequestPost = async (url_init, params) => {
    console.log(url_init);
    return await axios({
        method: 'post',
        url: url_init,
        headers: {
            "content-type": "application/json",            
        },
        data: params
    });
}