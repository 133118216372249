import React from "react";
import PropTypes from "prop-types";
import InspectionForm from './inspection_form';
import { WSRequestGetOldForms, WSRequestDelete} from "../../utils/inspection_form_api";
import TableForms from "./table_forms";
import "../../styles/table.css";



class MenuInspection extends React.Component {
  state={
    current_code_inspection: "",
    url_init: URL_INIT,

    loading_old_forms: true,
    old_forms: [],
    error_old_forms: false,
    type_response: "NEW",
    real_form_id: null,
    msg_delete: ''
  } 

  updateInfoCurrentForm = (form_id) => {
    this.setState({
      type_response: "OLD",
      real_form_id: form_id 
    })
  }
  
  setCodeInspection = (code) => {
    window.location = "/inspection_form/new?code=" + code + "&id=" + this.props.production_order;
    // this.setState({current_code_inspection: code});
  }

  async componentDidMount(){
    await this.getOldForms(this.props.production_order);
  }

  getOldForms = async (order) => {
    try {
      const url = this.state.url_init + "/api/v4/inspection_form/forms_by_production_order?order=" + order;
      const res = await WSRequestGetOldForms(url);
      const result = res.data;
      console.log(result);
      if (result.status){
        this.setState({
          old_forms: result.info.forms,
          loading_old_forms: false
        });
      } else {
        this.setState({error_old_forms: true});
      }    
    } catch (error) {
      console.log(error)
      this.setState({error_old_forms: true});
    }
  }

  removeFormInState = (form_id) => {
   return [...this.state.old_forms.filter((form) => form.id !== form_id)];
  }

  deleteForm = async (form_id) => {
    this.setState({loading_old_forms: true, msg_delete: ''});
    try {
      const url = this.state.url_init + "/api/v4/inspection_form/delete_form?form_id=" + form_id;
      const res = await WSRequestDelete(url);
      const result = res.data;
      console.log(result);
      if (result.status){
        const new_forms = this.removeFormInState(form_id);
        console.log(new_forms);
        this.setState({
          old_forms: new_forms,
          loading_old_forms: false,
          msg_delete: 'OK'
        });
      } else {
        this.setState({
          loading_old_forms: false,
          msg_delete: "ERROR"});
      }    
    } catch (error) {
      console.log(error)
    }

  }
  closeAlert = () => {
    this.setState ({msg_delete: ''});
  }

  render () {

    const list_of_forms = [{name: "Producto Terminado", code: "PRODUCT_FINISHED"},
                           {name: "Línea", code: "LINE"},
    ];
    const MenuInspection = (<div>
      {this.props.data ? 
      <h5>Inspecciones Disponibles para Orden #{this.props.production_order}</h5> : null
      }
      <div className="btn-group">
        {list_of_forms.map((form, i) =>{
          return (
            <button 
              type="button"
              className="mdl-button mdl-button--colored mdl-js-button mdl-js-ripple-effect"
              key={i} 
              onClick={() => this.setCodeInspection(form.code)}
            >
              {form.name}
            </button>
          )
        })}
      </div>
  </div>);
    return (
      <div className="" style={{"margin": "15px"}}>
        {this.props.data ?
        <div>
        {this.state.loading_old_forms ? 
         "Cargando... " : 
         <>
         <TableForms 
           title={"Formularios Pendientes"} 
           forms={this.state.old_forms.filter((form) => !form.is_ready)}
           deleteForm= {this.deleteForm}
           msg={this.state.msg_delete}
           closeAlert={this.closeAlert} />
         <TableForms 
           title={"Formularios Terminados"} 
           forms={this.state.old_forms.filter((form) => form.is_ready)}
           msg={''}
           closeAlert={null} />
         </>
        }
        </div> : null
        }
        {this.state.current_code_inspection === "" ?
          MenuInspection : 
          <div>
              <InspectionForm 
                code={this.state.current_code_inspection} 
                production_order={this.props.production_order} 
                url_init={this.state.url_init}
                inspector={this.props.current_user}
                real_form_id={this.state.real_form_id}
                type_response={this.state.type_response}
                updateInfoCurrentForm={this.updateInfoCurrentForm}
                />
              <button onClick={() => this.setCodeInspection("")}>Volver </button>
          </div>
        }                           
      </div>
    );
  }
}

MenuInspection.propTypes = {
  production_order: PropTypes.number,
  current_user: PropTypes.number,
};
export default MenuInspection
