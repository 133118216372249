// Component Header
// @author: Vicente Illanes 
// @version: 10.05.2022
// import React, { useState } from 'react';
import React  from 'react';
import Grid from '@mui/material/Grid';

import "../../styles/spinner.css";
import "../../styles/quality.css";



class CardForm extends React.Component {  
    
    /**
     * Envía a la vista del formulario
     * @param {String} id 
     */
    goToForm = (id) => {
        window.location = "/inspection_form/edit?id=" + id;
    }

    render () {
        const form = this.props.form;
        return(         
            <Grid item xs={3} sm={3} md={3} lg={3}>
                <div className="price-card">
                    <div className="price-card-body">
                        <b className="subscription">{form.name}</b>
                        <div className="price">N°{this.props.i}</div>
                        <div className="porcentage">{form.porcentage}%</div>                                                                       
                    </div>
                    <div className="price-card-footer">
                        <a href="#" className="btn" onClick={() => {this.goToForm(form.id)}}>Editar</a>
                    </div>
                </div>
            </Grid> 
        );
    }            
}


export default CardForm;


