/**
 * Component PlantDash
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FactoryIcon from '@mui/icons-material/Factory';
import LinearScaleIcon from '@mui/icons-material/LinearScale';
import { red } from '@mui/material/colors';
import { lightGreen } from '@mui/material/colors';
import TreeItem from '@mui/lab/TreeItem';
import Paper from '@mui/material/Paper';

 
const TreeMui = (props) => {
  return (
    <Paper elevation={1} >
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 200, overflowY: 'visible' }}
    >
      <TreeItem nodeId="1" label="Planta 2" icon={<FactoryIcon fontSize="large" sx={{ color: red[500] }} />}>
        <TreeItem nodeId="2" label="Linea 1a" icon={<LinearScaleIcon sx={{ color: red[500] }} />} />
      </TreeItem>
      <TreeItem nodeId="5" label="Planta 1" icon={<FactoryIcon sx={{ color: lightGreen[500] }}/>} >
        <TreeItem nodeId="10" label="Linea 1" icon={<LinearScaleIcon sx={{ color: red[500] }} />} />
        <TreeItem nodeId="6" label="Linea 2" icon={<LinearScaleIcon sx={{ color: lightGreen[500] }} />} >
         </TreeItem>
      </TreeItem>
    </TreeView>
    </Paper>
  )
 }
 
 export default TreeMui
 