export const responseLog = (response, show = true) => {
  if (show)
    console.log(
      "%s %s %o =>\n %o",
      response.config.method.toUpperCase(),
      response.config.url,
      response.config.params,
      response.data
    );
};

export const responseInfo = (response, show = true) => {
  if (show)
    console.info(
      "%s %s %o =>\n %o",
      response.config.method.toUpperCase(),
      response.config.url,
      response.config.params,
      response.data
    );
};

export const responseError = (response, show = true) => {
  if (show)
    console.error(
      "%s %s %o => %s %s %s",
      response.config.method.toUpperCase(),
      response.config.url,
      response.config.params,
      response.name,
      response.code,
      response.message
    );
};
