// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 29.09.2022
import React from "react";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Popover from "@mui/material/Popover";
import moment from "moment";
import DateRange from "./DateRange.js";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { WSRequestGet } from "../../apis/dash_order";
import { CompressOutlined } from "@mui/icons-material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  minHeight: "60px"
}));

class FiltersReportQuality extends React.Component {
  state = {
    dt_start: moment().add(-1, "days"),
    dt_end: moment(),
    anchorEl: null,
    filter_date: "last_24_hours",
    filter_state_form: "",
    filter_type_form: ""
  };
  prettyDate() {
    if (this.state.dt_end) {
      return `${this.state.dt_start.format(
        "DD/MM/YYYY HH:mm"
      )}-${this.state.dt_end.format("DD/MM/YYYY HH:mm")}`;
    } else {
      return `${this.state.dt_start.format("DD/MM/YYYY HH:mm")}`;
    }
  }

  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  onChangeRange = new_range => {
    this.setState({ dt_start: new_range[0], dt_end: new_range[1] });
    if (new_range[1]) {
      this.handleClose();
    }
  };

  /**
   * Captura el evento de cambiar el filtro de atajos de fecha
   * @param {Object} event
   */
  handleChangeFilterDate = event => {
    const filter = event.target.value;
    const range = this.calculateNewRange(filter);
    this.setState({
      filter_date: filter,
      dt_start: range[0],
      dt_end: range[1]
    });
  };

  /**
   * Captura el evento de cambiar el filtro de estado de formulario
   * @param {Object} event
   */
   handleChangeFilterForm = (event, key) => {
    const filter = event.target.value;
    const new_value = {};
    new_value[key] = filter;
    this.setState(new_value);
  };

  /**
   * Calcula el nuevo rango de fechas y horas a partir del atajo actual
   * @param {String} filter
   * @returns {Array<Moment>}
   */
  calculateNewRange = filter => {
    switch (filter) {
      case "":
        return [this.state.dt_start, this.state.dt_end];
      case "last_hour":
        return [moment().add(-1, "hours"), moment()];
      case "today":
        return [moment().startOf("day"), moment().endOf("day")];
      case "last_24_hours":
        return [moment().add(-24, "hours"), moment()];
      case "yesterday":
        return [
          moment().add(-24, "hours").startOf("day"),
          moment().add(-24, "hours").endOf("day")
        ];
      case "2_days_back":
        return [
          moment().add(-48, "hours").startOf("day"),
          moment().add(-48, "hours").endOf("day")
        ];
      case "3_days_back":
        return [
          moment().add(-72, "hours").startOf("day"),
          moment().add(-72, "hours").endOf("day")
        ];
      case "this_week":
        return [moment().startOf("week"), moment().endOf("week")];
      case "this_month":
        return [moment().startOf("month"), moment().endOf("month")];
      case "last_month":
        return [
          moment().add(-1, "months").startOf("month"),
          moment().add(-1, "months").endOf("month")
        ];
      default:
        throw "Error filtro desconocido";
    }
  };

  updateReportForms = () => {
    var variables = ""
    variables +=`?dt_start=${this.state.dt_start.format("DD/MM/YYYY HH:mm")}`;
    variables+= `&dt_end=${this.state.dt_end.format("DD/MM/YYYY HH:mm")}`;
    variables+= `&state=${this.state.filter_state_form}`;
    variables+= `&type=${this.state.filter_type_form}`;
    this.getFormsByFilter(variables);
  }

  getFormsByFilter = async (query) => {
    this.props.setLoadingForms(true);
    const url = URL_INIT;
    const controller = "api/v4/lineapp";
    const action = `get_forms_by_filters${query}`;
    try {
      const response = await WSRequestGet(url, controller, action, {});
      console.log(response.data);
      this.props.setCurrentReportForms(response.data.forms);
      this.props.setLoadingForms(false);
    } catch (err) {
      console.log(err);
      this.props.setLoadingForms(false);
    }
    

  }

  render() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
      <Box sx={{ flexGrow: 1, margin: "15px" }}>
        <Grid container spacing={2} columns={{ xs: 6, md: 8, lg: 12 }}>
          <Grid item xs={3} md={2} lg={3}>
            <Item>
              <Button
                variant="contained"
                style={{ marginTop: "12px"}}
                onClick={this.handleClick}
              >
                {this.prettyDate()}
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left"
                }}
              >
                <DateRange
                  dt_start={this.state.dt_start}
                  dt_end={this.state.dt_end}
                  onChangeRange={this.onChangeRange}
                />
              </Popover>
            </Item>
          </Grid>
          <Grid item xs={3} md={2} lg={3}>
            <Item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="selector-date">Atajo Fechas</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={this.state.filter_date}
                  label="Atajo Fechas"
                  onChange={this.handleChangeFilterDate}
                >
                  <MenuItem value={""}>Sin Filtro</MenuItem>
                  <MenuItem value={"last_hour"}>Última Hora</MenuItem>
                  <MenuItem value={"today"}>Hoy</MenuItem>
                  <MenuItem value={"last_24_hours"}>Últimas 24 horas</MenuItem>
                  <MenuItem value={"yesterday"}>Ayer</MenuItem>
                  <MenuItem value={"2_days_back"}>2 días atrás</MenuItem>
                  <MenuItem value={"3_days_back"}>3 días atrás</MenuItem>
                  <MenuItem value={"this_week"}>Esta Semana</MenuItem>
                  <MenuItem value={"this_month"}>Este Mes</MenuItem>
                  <MenuItem value={"last_month"}>Mes Anterior</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={3} md={2} lg={3}>
            <Item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="selector-state">Estado</InputLabel>
                <Select
                  labelId="selector-state"
                  id="selector-state"
                  value={this.state.filter_state_form}
                  label="Estado"
                  onChange={(e) => this.handleChangeFilterForm(e, "filter_state_form")}
                >
                  <MenuItem value={""}>Cualquiera</MenuItem>
                  <MenuItem value={"pending"}>Incompleto</MenuItem>
                  <MenuItem value={"finished"}>Finalizado</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={3} md={2} lg={3}>
            <Item>
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="selector-type">Tipo</InputLabel>
                <Select
                  labelId="selector-type"
                  id="selector-type"
                  value={this.state.filter_type_form}
                  label="Tipo"
                  onChange={(e) => this.handleChangeFilterForm(e, "filter_type_form")}
                >
                  <MenuItem value={""}>Cualquiera</MenuItem>
                  <MenuItem value={"LINE"}>Línea</MenuItem>
                  <MenuItem value={"PRODUCT_FINISHED"}>Producto Terminado</MenuItem>
                </Select>
              </FormControl>
            </Item>
          </Grid>
          <Grid item xs={6} md={8} lg={12}>
              <Button
                variant="contained"
                onClick={() => this.updateReportForms()}
              >
                Actualizar
              </Button>
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default FiltersReportQuality;
