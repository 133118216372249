/**
 * Component PlantSelect
 * @author: Luis Avello
 * @version 1.1.0
 * @updated 2022-11-06
 */
import React, { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";

const PlantSelect = (props) => {
  const { label, selected, list, handleChange } = props;

  return (
    <Box sx={{ minWidth: 120, pt: 2 }}>
      <FormControl fullWidth>
        <InputLabel id="plant-select-label">{label}</InputLabel>
        <Select
          labelId="plant-select-label"
          id="plant-select"
          value={selected}
          label="Planta"
          onChange={(event) => handleChange(event)}
        >
          {list.map((plant) => (
            <MenuItem key={plant.id} value={plant.id}>
              {plant.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default PlantSelect;
