import React from "react";
import TableLines from './table_lines';
import "../../styles/layouts.css";
import "../../styles/orders_card.css";
import { WSRequestGetProductionOrders} from "../../utils/production_orders_api";



class Index extends React.Component {
    state={
        active: "RUNNING", //OR COMPLETED, PLANNED
        production_orders: {"running": [], "completed": [], "planned":[]},
        current_date: new Date(),
        display: true,
        loading_data: true,
    }

    activeClass = (id) => {
        if (id === this.state.active){
            return "active_button_group"
        } else {
            return "normal_button_group"        
        }
    }

    setDisplay = () => {
        this.setState({
            display: !this.state.display
        });
    }

    activate = (id) => {
        this.setState({
            active: id,
        });
    }

    isCurrentToday = () => {
        return yyyymmdd(new Date()) === yyyymmdd(this.state.current_date);
    }

    filterDate = (production_order) => {
        const start_of_day = new Date(this.state.current_date.setHours(0,0,0));
        const end_of_day = new Date(this.state.current_date.setHours(23,59,59));
        const start_order = new Date(production_order.start_at);
        const end_order = new Date(production_order.end_at)

        console.log(start_order)
        console.log(end_order)

        var inside_order = start_of_day <= start_order;
        inside_order = inside_order && (new Date(production_order.end_at) <= end_of_day);
        console.log("INSIDE: ")
        console.log(inside_order)
        
        var start_with_day = start_of_day <= start_order;
        start_with_day = start_with_day && (end_of_day >= start_order)
        console.log("START WITH: ")
        console.log(start_with_day)

        var end_with_day = start_of_day <= end_order;
        end_with_day = end_with_day && (end_of_day >= end_order)
        console.log("END WITH: ")
        console.log(end_with_day)

        return inside_order || start_with_day ||  end_with_day
    }

    updateProductionOrders = (data, type) => {
        this.setState({
            production_orders: data,
        })
    }

    DashOrder_DateLeft = () => {
        this.DashOrder_DateFactor(-1);
    }

    DashOrder_DateRight = () => {
        this.DashOrder_DateFactor(1);
    }

    DashOrder_DateFactor = (factor) => {
        const new_date = new Date(this.state.current_date.getTime() + (3600 * 1000 * 24 * factor));
        this.setState({current_date: new_date, loading_data: true});
        this.getDataProductionOrders(new_date);
    }
    
    getDataProductionOrders = async (date = new Date()) => {
        try {
            const suffix_url = "/api/v3/dash/production_orders?line_id=0&date=" + dateFormat(date)
            const res = await WSRequestGetProductionOrders(suffix_url, {});
            const result = res.data;
            console.log(result);
            this.setState({
              loading_data: false
            })
            this.updateProductionOrders(result, "RUNNING");
          } catch (error) {
            console.log(error)
          }
    }

  render () {
    var current_orders = this.state.production_orders[this.state.active.toLowerCase()];
    current_orders = current_orders.filter( (prod_order) => this.filterDate(prod_order) || (this.state.active !== "COMPLETED"))
    return(
        <div>
            <div className="mdl-grid">
                <div className={"mdl-cell mdl-cell--"+12+"-col graybox "}>
                    <div className="mdl-grid">
                        <div className={"mdl-cell mdl-cell--"+4+"-col graybox "}>
                            <div className="mdl-button-group">
                                <button id="RUNNING" onClick={() => this.activate("RUNNING")} className={"mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect " + this.activeClass("RUNNING")} style={{"margin": "5px"}}>
                                    Producción
                                </button>
                                <button id="PLANNED" onClick={() => this.activate("PLANNED")} className={"mdl-button mdl-js-button mdl-button--raised "+ this.activeClass("PLANNED")} style={{"margin": "5px"}}>
                                    Programadas
                                </button>
                                    <button id="COMPLETED" onClick={() => this.activate("COMPLETED")} className={"mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect "+ this.activeClass("COMPLETED")} style={{"margin": "5px"}}>
                                    Terminadas
                                </button>
                            </div>
                        </div>
                        {this.state.active === "COMPLETED" ?
                        <div className={"mdl-cell mdl-cell--"+6+"-col graybox "}>
                            <h1 className="mdl-card__title-text title">
                                <a id="button_left" href="#" onClick={() => this.DashOrder_DateLeft()}><i className="pms-i chevron-left gray title_order"></i></a>
                                <span htmlFor="button_left" className="mdl-tooltip">{"Día Anterior"}</span>
                                <span id="date_filter" style={{
                                    "fontWeight": "bold",
                                    "color": "#3f51b5"
                                }}>{yyyymmdd(this.state.current_date)}</span> 
                                {!this.isCurrentToday() ? 
                                <><a id="button_right" href="#" onClick={() => this.DashOrder_DateRight()}><i className="pms-i chevron-right gray title_order"></i></a>
                                <span htmlFor="button_right" className="mdl-tooltip">{"Día Siguiente"}</span></> : null} 
                            </h1>
                        </div> : null}
                    </div>
                </div>
                <div className="mdl-cell mdl-cell--2-col-desktop mdl-cell--4-col-phone mdl-cell--4-col-tablet"> 
                    <div className="card-square mdl-card mdl-shadow--2dp">
                        <div className="mdl-card__title"> 
                        <h1 className="mdl-card__title-text"
                        style={{
                            "fontWeight": "bold",
                            "color": "#3f51b5",
                            "fontSize": "15px",
                        }}>En Línea</h1>
                        </div>
                        <div className={"mdl-card--expand " + (this.state.display ? "display_true" : "display_none")}>
                            <div id="tree" className="tree_dash" ></div>
                        </div>

                    </div>
                </div> 
                <div className={"mdl-cell mdl-cell--10-col-desktop mdl-cell--8-col-phone  mdl-cell--8-col-tablet graybox "}>
                    <TableLines 
                        updateProductionOrders={this.updateProductionOrders}
                        current_orders={current_orders}
                        current_date={this.state.current_date}
                        getDataProductionOrders={this.getDataProductionOrders}
                    />
                </div>
            </div>
        </div>
    );
  }
}
export default Index

//Dado una fecha de tipo Date, entrega fecha en formato string "YYYY-MM-DD"
export const yyyymmdd = (date) => {
    var mm = date.getMonth() + 1; // getMonth() is zero-based
    var dd = date.getDate();
  
    return [date.getFullYear(),
            (mm>9 ? '' : '0') + mm,
            (dd>9 ? '' : '0') + dd
           ].join('-');
  };

  //Dado una fecha de tipo string, entrega fecha revirtendo el orden en dia-mes-año o año-mes-dia
export const reverseDate = (date) => {
    return date.split("-").reverse().join("/")
}

//Dado una fecha de tipo Date, entrega fecha en formato string "DD-MM-YYYY"
export const dateFormat = (date) => {
    var ans = yyyymmdd(date);
    return reverseDate(ans);
}
