// Component Header
// @author: Vicente Illanes 
// @version: 10.05.2022
import React, { Component } from 'react';
import "../../styles/menu.css";
import "../../styles/modal.css";
import "../../styles/inspection_form/tab.css"
import "../../styles/lateral_menu_form.css";

class InspectionForm extends Component{  

    /**
     * Obtiene el nombre del formulario
     * @returns {String}
     */
    getNameInspectionForm = () => {
        return this.props.form.name
    }   

    render(){
        
        return(
            <div className="header_menu">
            <h4>
                Formulario de {this.getNameInspectionForm()}
            </h4>
            <div className="mdl-grid subhead">
                <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-desktop">
                    <h6>{this.props.form.code_form}</h6>
                </div>
                <div className="mdl-cell mdl-cell--2-col mdl-cell--4-col-desktop">
                    <h6>{this.props.form.review}</h6>
                </div>
                <div className="mdl-cell mdl-cell--3-col mdl-cell--4-col-desktop">
                    <h6>{this.props.form.date}</h6>
                </div>
            </div>
        </div>
            
        )
    }
}

export default InspectionForm;


