/**
 * Component Chronology Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-17
 */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsVariwide from "highcharts/modules/variwide";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);
HighchartsVariwide(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const Chronology = (props) => {
  const { title, subtitle, x_title, y_title, height = 100, serie } = props;

  const data_example = [
    {
      x: 1666026533961.807,
      y: 0,
      z: 0,
      status: "",
      reasons: "",
      color: "#0000ff",
      description: "",
    },
    {
      x: 1666026533962.807,
      y: 1,
      z: 237789.193,
      status: "Ejecución",
      reasons: "",
      color: "#40EF0A",
      description: "",
    },
    {
      x: 1666026771752.0,
      y: 1,
      z: 71796.0,
      status: "Paro",
      reasons: "Not justified",
      color: "#ff0000",
      description: "",
    },
    {
      x: 1666026843548.0,
      y: 1,
      z: 100342.0,
      status: "Ejecución",
      reasons: "",
      color: "#40EF0A",
      description: "",
    },
    {
      x: 1666026943889.9998,
      y: 1,
      z: 87454.0,
      status: "Paro",
      reasons: "Not justified",
      color: "#ff0000",
      description: "",
    },
  ];

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "variwide",
      zoomType: "x",
      height: height,
      animation: true,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: title,
      align: "left",
      margin: 0,
      style: {
        color: "#3f51b5",
        fontSize: "12px",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: x_title,
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: y_title,
      },
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      xDateFormat: "%A %e %b, %H:%M:%S",
      pointFormatter: function () {
        var point_tooltip =
          '<span style="color:' +
          this.series.color +
          '">\u25CF</span> ' +
          this.series.name +
          ": <b>" +
          this.status +
          "</b><br/>" +
          t.indicator.duration +
          " <b>" +
          Highcharts.dateFormat(" %H:%M:%S", this.z) +
          "</b><br/>";
        point_tooltip += "<b>" + this.reasons + "</b><br/>" + this.description;
        return point_tooltip;
      },
    },
    plotOptions: {
      series: {
        animation: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    time: {
      timezoneOffset: -TIMEZONE_OFFSET,
    },
    series: [serie],
  });

  useEffect(() => {
    //console.log("Use Effect Column");
    setChartOptions({
      series: [serie],
    });
  }, [serie]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default Chronology;
