/**
 * Component Speed Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-18
 */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const Speed = (props) => {
  const { title, subtitle, x_title, y_title, height = 100, serie } = props;

  const data_example = [
    [1666199057000, 76.0],
    [1666199117000, 26.0],
    [1666199177000, 140.0],
    [1666199237000, 52.0],
    [1666199297000, 0.0],
    [1666199357000, 122.0],
    [1666199417000, 126.0],
    [1666199477000, 104.0],
  ];

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "line",
      zoomType: "x",
      height: height,
      animation: true,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: title,
      style: {
        color: "#3f51b5",
        fontSize: "15px",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    xAxis: {
      type: "datetime",
      title: {
        text: x_title,
        enabled: false,
      },
    },
    yAxis: {
      title: {
        text: y_title,
        enabled: false,
      },
      labels: {
        enabled: true,
      },
    },
    tooltip: {
      valueSuffix: " u/min",
      xDateFormat: "%A %e %b, %H:%M",
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    time: {
      timezoneOffset: -TIMEZONE_OFFSET,
    },
    series: [serie],
  });

  useEffect(() => {
    //console.log("Use Effect Column");
    setChartOptions({
      series: [serie],
    });
  }, [serie]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default Speed;
