import React from "react";
import SpinnerLoading from './spinner_layout';

class TableLayout extends React.Component {
    render () {
        return(
            <div>
                {this.props.loading ?
                <SpinnerLoading 
                    msg={this.props.msg}
                /> :
                <table className="mdl-data-table mdl-js-data-table mdl-shadow--2dp">
                    <thead>
                        <tr>
                            {this.props.header.map((name_col, i) => {
                                return (
                                    <th key={i}>{name_col["name"]}</th>
                                )
                            })
                            }                         
                        </tr>
                    </thead>
                    <tbody>
                        {this.props.items.map((item, i) => {
                            
                            return (
                                <tr key={i} className="mdl-data-table__cell--non-numeric">
                                    {this.props.header.map((id_col, j) => {
                                        return (
                                            <td key={i+"_"+j}>{item[id_col["id"]]}</td>
                                        )
                                        })
                                    } 
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            }
            </div>
        );
    }
}
export default TableLayout
