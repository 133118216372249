// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 29.09.2022
import React from "react";
import "../../styles/spinner.css";
import "../../styles/quality.css";
import DateRangePicker from "./DateRangePicker.js";


class DateRange extends React.Component {
    /**Quita marca de agua del calendario picker */
  componentDidMount(){
    moment.locale('es');
    const date  = document.getElementById('date_range_picker');
    date.children[0].children[0].children[0].children[0].style.display = "none";
  }
  render() {
    return (
      <div id="date_range_picker">
        <DateRangePicker 
          dt_start={this.props.dt_start} 
          dt_end={this.props.dt_end}
          onChangeRange={this.props.onChangeRange} />
      </div>
    );
  }
}

export default DateRange;
