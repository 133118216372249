/**
 * Component Column Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-18
 */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const Column = (props) => {
  const {
    title,
    titleSize = "14px",
    subtitle,
    x_title,
    y_title,
    height = 100,
    serie,
    unit = "unidades",
  } = props;

  const data_example = [
    { x: 1641013200000.0, y: 1886755 },
    { x: 1643691600000.0, y: 2218587 },
    { x: 1646110800000.0, y: 2022084 },
    { x: 1648789200000.0, y: 1823625 },
    { x: 1651381200000.0, y: 1906398 },
  ];

  useEffect(() => {
    Highcharts.setOptions({
      lang: {
        viewFullscreen: t.system.view_full_screen,
        exitFullscreen: t.system.exit_full_screen,
        printChart: t.chart.print_chart,
        downloadJPEG: t.chart.download_jpeg,
        downloadPNG: t.chart.download_png,
        downloadPDF: t.chart.download_pdf,
        downloadCSV: t.chart.download_csv,
        downloadXLS: t.chart.download_xls,
        weekdays: t.date.day_names,
        shortWeekdays: t.date.abbr_day_names,
        months: t.date.month_names,
        shortMonths: t.date.abbr_month_names,
        resetZoom: t.system.reset_zoom,
      },
    });
  }, []);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      type: "column",
      zoomType: "x",
      height: height,
      animation: true,
      spacingTop: 5,
      spacingBottom: 0,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: title,
      margin: 5,
      style: {
        color: "#3f51b5",
        fontSize: titleSize,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    subtitle: {
      text: subtitle,
    },
    xAxis: {
      title: {
        text: x_title,
      },
      type: "datetime",
      minRange: 3600000,
      crosshair: true,
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: y_title,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    time: {
      timezoneOffset: -TIMEZONE_OFFSET,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y} ' +
        unit +
        "</b></td></tr>",
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [serie],
  });

  useEffect(() => {
    //console.log("Use Effect Column");
    setChartOptions({
      series: [serie],
    });
  }, [serie]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default Column;
