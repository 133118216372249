/**
 * Component Column Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-18
 */
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import HighchartsExportData from "highcharts/modules/export-data";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);
HighchartsExporting(Highcharts);
HighchartsAccessibility(Highcharts);

const ColumnMultiple = (props) => {
  const { title, x_title, y_title } = props;

  const [type_chart, setTypeChart] = React.useState("chronology");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open_menu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
    console.log("Cierre Menu");
  };

  const handleClickMenuItem = (event) => {
    setAnchorEl(null);
    let newTypeChart = event.target.getAttribute("value");
    console.log(newTypeChart);
    setTypeChart(newTypeChart);
  };

  let menu = (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open_menu}
      onClose={handleCloseMenu}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      <MenuItem value="chronology" onClick={handleClickMenuItem}>
        Ultimos 12 Meses
      </MenuItem>
      <MenuItem value="speed" onClick={handleClickMenuItem}>
        Año Actual
      </MenuItem>
      <MenuItem value="production" onClick={handleClickMenuItem}>
        Mes Actual
      </MenuItem>
      <MenuItem value="production" onClick={handleClickMenuItem}>
        Semana Actual
      </MenuItem>
    </Menu>
  );

  const data_example = [
    { x: 1641013200000.0, y: 1886755 },
    { x: 1643691600000.0, y: 2218587 },
    { x: 1646110800000.0, y: 2022084 },
    { x: 1648789200000.0, y: 1823625 },
    { x: 1651381200000.0, y: 1906398 },
    { x: 1654059600000.0, y: 1937385 },
    { x: 1656651600000.0, y: 1337270 },
    { x: 1659330000000.0, y: 1697047 },
    { x: 1662008400000.0, y: 2109766 },
  ];

  const data_example_empty = [];

  const options = {
    chart: {
      type: "column",
      height: "100",
    },
    title: {
      text: title,
      margin: 5,
      style: {
        color: "#3f51b5",
        fontSize: "14px",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      type: "datetime",
      crosshair: true,
      labels: {
        enabled: true,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: y_title,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat:
        '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y} unidades</b></td></tr>',
      footerFormat: "</table>",
      shared: true,
      useHTML: true,
    },
    plotOptions: {
      column: {
        pointPadding: 0.2,
        borderWidth: 0,
      },
    },
    series: [{ name: "", data: data_example_empty }],
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <Typography />
        <Typography
          variant="button"
          align="center"
          color="primary"
          sx={{ mt: 1 }}
        >
          Ultimos 12 Meses
        </Typography>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        {menu}
      </Stack>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </Box>
  );
};

export default ColumnMultiple;
