// Component MenuCreationForm
// @author: Vicente Illanes
// @version: 29.09.2022
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import "../../styles/spinner.css";
import "../../styles/quality.css";
import TableReportQuality from "./Table.js";
import FiltersReportQuality from "./FiltersReportQuality.js";

/**
 * Columnas para la tabla de reporte
 */
const headCells = [
    {
      id: "date",
      numeric: false,
      disablePadding: false,
      label: "Fecha"
    },
    {
      id: "order_id",
      numeric: true,
      disablePadding: false,
      label: "Orden"
    },
    {
      id: "name",
      numeric: false,
      disablePadding: false,
      label: "Tipo"
    },
    {
      id: "state",
      numeric: false,
      disablePadding: false,
      label: "Estado"
    },
    {
      id: "porcentage",
      numeric: true,
      disablePadding: false,
      label: "Completitud (%)"
    },
    {
      id: "action",
      numeric: false,
      disablePadding: false,
      label: "Acción"
    }
  ];  
class ReportQuality extends React.Component {

  render() {
    return (
      <Grid item xs={12} sm={12} md={12}>
        <FiltersReportQuality 
          setCurrentReportForms={this.props.setCurrentReportForms}
          setLoadingForms={this.props.setLoadingForms} />
        {this.props.loadingForms ? (<Box
          sx={{
            height: "max-content"
          }}
        >
          {[...Array(5  )].map((_, i) => (
            <Skeleton variant="rectangular" sx={{ my: 4, mx: 1 }} key={i} />
          ))}
        </Box>) :
        <TableReportQuality 
          title_table="Reportería Formularios" 
          headCells={headCells}
          rows={this.props.forms}
         /> }
      </Grid>
    );
  }
}

export default ReportQuality;
