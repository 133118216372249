/**
 * Component PlantView
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-11-05
 */
import React, { useEffect, useState } from "react";
import axios from "axios";
import Grid from "@mui/material/Unstable_Grid2";
import Container from "@mui/material/Container";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import LineItem from "./line_item";
import PlantKpiDay from "./plant_kpi_day";
import PlantSelect from "./plant_select";
import PlantKpiHistoric from "./plant_kpi_historic";
import TreePms from "../tree/tree_pms";
import useInterval from "../hooks/interval";

import {
  responseLog,
  responseInfo,
  responseError,
} from "../../utils/response_console";

const PlantView = (props) => {
  const [plant, setPlant] = useState({ id: "", name: "", active_lines: [] });
  const [plants, setPlants] = useState([]);
  const [update, setUpdate] = useState(0);
  const timeUpdate = 60000;

  useInterval(() => {
    setUpdate(update + 1);
    console.log("Update " + update);
  }, timeUpdate);

  const handleChangePlant = (event) => {
    let [first] = plants.filter((item) => item.id == event.target.value);
    setPlant(first);
  };

  const changePlant = (id) => {
    let [first] = plants.filter((item) => item.id == id);
    setPlant(first);
  };

  useEffect(() => {
    axios
      .get("/api/react/v1/dashboard/plants")
      .then((response) => {
        responseInfo(response, false);
        setPlants(response.data);
        let [first] = response.data;
        setPlant(first);
      })
      .catch((error) => {
        responseError(error);
      });
  }, [update]);

  return (
    <Container maxWidth="false">
      <Grid container spacing={2}>
        <Grid xs={12} sx={{ display: { xs: "block", sm: "none" } }}>
          <PlantSelect
            label="Planta"
            list={plants}
            selected={plant.id}
            handleChange={handleChangePlant}
          />
        </Grid>
        <Grid sm={4} md={2} sx={{ display: { xs: "none", sm: "block" } }}>
          <TreePms plants={plants} changePlant={changePlant} />
        </Grid>
        <Grid xs={12} md={5}>
          <PlantKpiDay plant={plant} update={update} />
        </Grid>
        <Grid xs={12} md={5}>
          <PlantKpiHistoric plant={plant} update={update} />
        </Grid>
        {plant.active_lines.map((line) => (
          <Grid xs={12} key={line.id} md={3}>
            <LineItem line={line} update={update} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default PlantView;

/**
 * <PlantSelect
            label="Planta"
            list={plants}
            selected={plant.id}
            handleChange={handleChangePlant}
          />
 */
