import React, { useState } from 'react';
import "../../styles/orders_card.css";


const  CardOrders = (props) =>{ 
    const [Display, SetDisplay] = useState(true);

    var  uploadLinkOrders = null;
    if (props.span_id  === "num_planned") {
        uploadLinkOrders = (<><a id="button_upload_orders" href="/production_orders/upload_data" tabIndex="0">
            <i className="pms-i upload gray title_order"></i>
            </a><span htmlFor="button_upload_orders" className="mdl-tooltip">{props.system_upload +' '+props.model_production_orders} </span></>);

    }

    const setDisplay = () => {
        SetDisplay(!Display);
    }

    var DateFilter, SeeTable = null;
    if (props.span_id  ===  "num_completed"){
        DateFilter = (<>
        <a id="button_left" href="#" onClick={() => props.DashOrder_DateLeft()}><i className="pms-i chevron-left gray title_order"></i></a>
        <span htmlFor="button_left" className="mdl-tooltip">{props.previous_day}</span>
        <span id="date_filter">{props.current_date}</span> 
        <a id="button_right" href="#" onClick={() => props.DashOrder_DateRight()}><i className="pms-i chevron-right gray title_order"></i></a>
        <span htmlFor="button_right" className="mdl-tooltip">{props.next_day}</span> </>);
        SeeTable=(<><a id="button_see_table" href="/production_orders/list_finished" tabIndex="0">
        <i className="pms-i th-list gray title_order"></i>
    </a>
    <span htmlFor="button_see_table" className="mdl-tooltip">{props.button_see +' '+ props.system_table}</span></>);
    }

    return (
        <div className="mdl-cell mdl-cell--4-col-desktop mdl-cell--8-col-tablet mdl-cell--4-col-phone "> {/* CELL */}
            <div className="card-square mdl-card mdl-shadow--2dp admin_card"> {/* CARD */}
                <div className="mdl-card__title"> 
                    <h1 className="mdl-card__title-text title">{props.system}(<span id={props.span_id}>{props.orders.length}</span>)
                        {DateFilter}
                    </h1>
                    {SeeTable}
                    {uploadLinkOrders}
                    <a href="#" id={props.button_slide_id} className="button_slide_orders" onClick={() => setDisplay()}>
                        <i className="pms-i compress gray title_order"></i>
                    </a>
                    <span htmlFor={props.button_slide_id} className="mdl-tooltip mdl-tooltip--left">{props.button}</span>
                </div>
                <ul id={props.orders_id} className={"demo-list-three mdl-list order_list " + (Display ? "display_true" : "display_none")}>
                    {props.list_orders(props.orders)}
                </ul>
            </div> {/* CARD */}
        </div>
    )
} 

export default CardOrders;