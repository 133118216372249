import axios from "axios";

export const WSRequestGetProductionOrders = async (url_init, params) => {
    return await WSRequestPost(url_init, params);
}



const WSRequestPost = async (url_suffix, params) => {
    const url = URL_INIT+ url_suffix;
    return await axios({
        method: 'get',
        url: url,
        headers: {
            "content-type": "application/json",            
        },
        data: params
    });
}