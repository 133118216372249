/**
 * Component Gauge Chart
 * @author: Luis Avello
 * @version 1.0.0
 * @updated 2022-10-11
 */
import React, { useEffect, useState } from "react";
import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsAccessibility from "highcharts/modules/accessibility";
import HighchartsExporting from "highcharts/modules/exporting";
import SolidGauge from "highcharts/modules/solid-gauge";
import HighchartsReact from "highcharts-react-official";

HighchartsMore(Highcharts);
HighchartsAccessibility(Highcharts);
HighchartsExporting(Highcharts);
SolidGauge(Highcharts);

const Gauge = (props) => {
  const { title = "", height, size = "18px", titleSize = "14px", data } = props;
  const roundValue = (num) => {
    Math.round(num * 10) / 10;
  };

  const [chartOptions, setChartOptions] = useState({
    title: {
      text: title,
      margin: 0,
      style: {
        color: "#3f51b5",
        fontSize: titleSize,
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
    chart: {
      type: "solidgauge",
      width: null,
      height: height,
      animation: true,
      spacingTop: 0,
      spacingBottom: 5,
      spacingLeft: 0,
      spacingRight: 0,
    },
    pane: {
      center: ["50%", "50%"],
      size: "100%",
      startAngle: 0,
      endAngle: 360,
      background: {
        backgroundColor:
          (Highcharts.theme && Highcharts.theme.background2) || "#EEE",
        innerRadius: "60%",
        outerRadius: "100%",
        shape: "arc",
      },
    },
    tooltip: { enabled: false },
    yAxis: {
      min: 0,
      max: 100,
      stops: [
        [0.3, "#FF0000"], // red
        [0.5, "#FFFF00"], // yellow
        [0.7, "#40EF0A"], // green
      ],
      lineWidth: 0,
      minorTickInterval: null,
      tickPixelInterval: 400,
      tickWidth: 0,
      title: { y: -70, width: 20 },
      labels: { enabled: false },
    },
    plotOptions: {
      solidgauge: {
        dataLabels: {
          y: -17,
          borderWidth: 0,
          useHTML: true,
        },
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      enabled: false,
    },
    series: [
      {
        name: title,
        data: data,
        dataLabels: {
          format:
            '<div style="text-align:center"><span style="font-weight:500;font-size:' +
            size +
            ";color:" +
            ((Highcharts.theme && Highcharts.theme.contrastTextColor) ||
              "black") +
            '">{y}%</span><br/>' +
            "</div>",
        },
        tooltip: {
          valueSuffix: "%",
        },
      },
    ],
  });

  useEffect(() => {
    //console.log("Use Effect Gauge");
    setChartOptions({
      series: [{ data: data }],
    });
  }, [data]);

  return <HighchartsReact highcharts={Highcharts} options={chartOptions} />;
};

export default Gauge;
